import { FullComponent } from './../full.component';
import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

import { MenuItems } from '../../../shared/menu-items/menu-items';
import { HorizontalMenuItems } from '../../../shared/menu-items/horizontal-menu-items';
import { VerticalSidebarService } from './vertical-sidebar.service';
import {
  MatSnackBar, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SessionExpiredModalComponent } from 'src/app/session-expired-modal/session-expired-modal.component';
import { AppComponent, removeBackRequest } from 'src/app/app.component';
import { CompanyDialogContent } from 'src/app/company/company.component';
import * as moment from 'moment';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
  styleUrls: ['./vertical-sidebar.scss']
})

export class VerticalAppSidebarComponent implements OnDestroy, OnInit, DoCheck {
  activeMenu: any = "Dashboards";
  navbarStretch: any = false;
  sidebarWidth: any;
  mainWidth: any;
  sideIcons: any;
  sidebarElements: any;
  interval: any;
  sidebarContent: any;
  state: any;
  countProjects: any;
  countPosition: any;
  max_position: any;
  max_project: any;
  countModaleExpired = 0;
  idPlanCurrent: any;
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;
  currentUser: any;
  counterListRequest: any;
  counterListInvite: any;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end'; // start, center, end, left, right
  verticalPosition: MatSnackBarVerticalPosition = 'top'; // top, bottom

  private _mobileQueryListener: () => void;
  status = true;

  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;
  textLogo: any;
  logo: any;
  sidebarImplementations: any = document.getElementsByClassName("triangle-custom");
  versionProduct: any;
  currentDocStatus: any;
  dateNow: any;
  limitDateDoc: any;
  expiredDate = false;
  planStatus: any;
  responsiveWidth = window.innerWidth;
  user_menus: any;
  functions: any;
  viewButtonAddProject = false;
  viewButtonAddProfile = false;
  viewButtonPositionTimeMaterial = false;
  viewButtonPositionFixedPrice = false;
  companyCounter: any = 0
  servicesCounter: any = 0
  subMenuOpen = false
  token: any;
  @Output() onSelectedMenuItem = new EventEmitter<boolean>();
  counterServices: any;
  isEsprinet = false;
  isDomainEsprine = false;
  url: any
  returned: any = '';
  is_old_plan: any;
  transactionFee: any;
  namePlanCurrent: any;
  freePlan: any;
  limitations: any;
  limitationsArray: any;
  manageUserBlock: any;
  discoverCompatibleProfileBlock: any;
  timeMaterialBlock: any;
  fixedPriceBlock: any;
  showEstimateBlock: any;
  hireBlock: any;
  chatBlock: any;
  requestAvailableBlock: any;
  downloadCVBlock: any;
  informationBlock: any;
  emailsContactBlock: any;
  downloadCompanyReportBlock: any;
  searchPartnerBlock: any;
  existInLimitationSearch: any;
  getPlanStatusRes: any;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    media: MediaMatcher,
    private _service: VerticalSidebarService,
    public menuItems: HorizontalMenuItems,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    public dialog: MatDialog,
    public fullComponent: FullComponent,
    private cdRef: ChangeDetectorRef,
    public appComponent: AppComponent,
  ) {

    setTimeout(() => {
      this.sidebarWidth = document.getElementsByClassName("mat-sidenav")[0];
      this.sidebarContent = document.getElementsByClassName("mat-nav-list")[0];
      this.mainWidth = document.getElementsByClassName("mat-drawer-content")[0];
      this.sideIcons = document.getElementsByClassName("mat-icon");
      this.sidebarElements = document.getElementsByClassName("sidebar-elements");

    }, 3000);

    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
    this.functions = JSON.parse(sessionStorage.getItem("functions")!);
    if (this.currentUser.role == 'Company Administrator' || this.currentUser.role == 'Company Administrator Management') {
      this.isEsprinet = true
    }
    if (window.location.host.includes('esprinet')) {
      this.isDomainEsprine = true
    }
    // //console.log('functions');
    console.log(this.functions);
    // //console.log('end functions');

    if (this.functions && this.functions.length != 0) {
      for (let i = 0; i < this.functions.length; i++) {
        if (this.functions[i]['state'] == 'add_project') {
          if (this.functions[i]['status'] == 1) {
            this.viewButtonAddProject = true;
          } else {
            this.viewButtonAddProject = false;
          }
        }
        if (this.functions[i]['state'] == 'add_profile') {
          if (this.functions[i]['status'] == 1) {
            this.viewButtonAddProfile = true;
          } else {
            this.viewButtonAddProfile = false;
          }
        }
        if (this.functions[i]['state'] == 'position_list_t_m') {
          if (this.functions[i]['status'] == 1) {
            this.viewButtonPositionTimeMaterial = true;
          } else {
            this.viewButtonPositionTimeMaterial = false;
          }
        }
        if (this.functions[i]['state'] == 'position_list_f_p') {
          if (this.functions[i]['status'] == 1) {
            this.viewButtonPositionFixedPrice = true;
          } else {
            this.viewButtonPositionFixedPrice = false;
          }
        }
      }
    } else {
      if (this.currentUser.role != 'Administrator' && this.currentUser.role != 'Admin Management') {
        this._service.getFunctionCompany().then(res => {
          sessionStorage.removeItem('functions');
          sessionStorage.setItem('functions', JSON.stringify(res.data));

          window.location.reload();

        }).catch(error => {
          if (error.status == 401) {
            this.fullComponent.refreshToken(1);

          } else {
            this.manageError();

          }

        });
      } else {
        this.functions = [];
      }
    }

    if (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') {
      this.viewButtonPositionTimeMaterial = true;
      this.viewButtonPositionFixedPrice = true;
    }

    // maticon add custom icons
    this.matIconRegistry.addSvgIcon(
      "client-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/client.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "collaborations-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/collaborations.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "company-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/company.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "cv-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/cv.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "dashboard-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/dashboard.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "employee-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/employee.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "job-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/job.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "plan-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/plan.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "search-profile-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/search-profilew.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "supplier-request-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/supplier-request.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "supplier-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/supplier.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "tech-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/tech.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "manage-user-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/manage-resources.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "available-profiles-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/available-profiles.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "descriptions-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/descriptions.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "profile-review-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/profile-review.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "bell-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/bell.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "company-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/company.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "search-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/search.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "position-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/position.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "open-position-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/business-bag.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "cake-chart-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/cake-chart.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "upload-cv-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/upload.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "favourites-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/new-card-icons/saved-profile-white.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "academy-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/academy.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "languages-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/Language_White.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "freelance-profile-custom",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/new-card-icons/freelance-white.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "search-supplier",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/cerca-fornitori.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "metrics",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/metrics.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "search-services",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/cerca-servizi-admin-white.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "services_list",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/images/sidebar-icons/servizi-admin-white.svg")
    );

    this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
    this.url = window.location.hostname;

    // if (this.versionProduct == 2) {
    //   this.logo = 'assets/images/new-card-icons/logo-negative.svg';
    //   if (window.location.hostname.toLowerCase().includes('esprinet')) {
    //     this.logo = 'assets/images/new-card-icons/esprinet-logo.svg';
    //   }
    // } else if (this.versionProduct == 1) {
    //   if (window.location.host == "procurement.timeflow.it") {
    //     this.logo = 'assets/images/new-card-icons/logo-negative.svg';
    //   } else if (window.location.host == "whitelabel.daytrend.it") {
    //     this.logo = 'assets/images/new-card-icons/logo-negative.svg';
    //   } else if (window.location.host == "procurement.daytrend.it") {
    //     this.logo = 'assets/images/new-card-icons/logo-negative.svg';
    //   } else if (window.location.host == "dev.mkt.daytrend.it") {
    //     this.logo = 'assets/images/new-card-icons/logo-negative.svg';
    //   } else if (window.location.hostname.toLowerCase().includes('esprinet')) {
    //     this.logo = 'assets/images/new-card-icons/esprinet-logo.svg';
    //   } else {
    //     this._service.getLogoCompany().then(res => {
    //       if (res.success) {
    //         this.logo = res.data
    //       } else {
    //         this.logo = 'assets/images/new-card-icons/logo-negative.svg';
    //       }
    //     }).catch(error => {

    //       this.manageError();

    //     });
    //   }
    // }

    var ADMINMENUITEMS: any = [];
    if (this.versionProduct == 2) {
      ADMINMENUITEMS = [
        {
          state: 'company',
          name: 'Company',
          type: 'link',
          icon: 'company-custom'
        },
        {
          state: 'search_supplier',
          name: 'Search Company',
          type: 'link',
          icon: 'search-supplier'
        },
        {
          state: 'find_services',
          name: 'Find Services',
          type: 'link',
          icon: 'search-services',
        },
        {
          state: 'profile_available',
          name: 'Profile Available',
          type: 'link',
          icon: 'available-profiles-custom'
        },
        {
          state: 'position_list',
          name: 'Position List',
          type: 'link',
          icon: 'open-position-custom'
        },
        {
          state: 'accademy_profiles',
          name: 'Profile Accademy',
          type: 'link',
          icon: 'academy-custom'
        },

        {
          state: 'technology',
          name: 'Technology',
          type: 'link',
          icon: 'tech-custom'
        },
        {
          state: 'job_sector',
          name: 'Job Sector',
          type: 'link',
          icon: 'cake-chart-custom'
        },
        {
          state: 'description_pages',
          name: 'Description Pages',
          type: 'link',
          icon: 'descriptions-custom'
        },
        {
          state: 'employee_review',
          name: 'Employee Review',
          type: 'link',
          icon: 'profile-review-custom'
        },
        {
          state: 'email_notifications',
          name: 'Email Notifications',
          type: 'link',
          icon: 'bell-custom'
        },
        {
          state: 'pending_inscriptions',
          name: 'Pending Inscriptions',
          type: 'link',
          icon: 'company-custom'
        },
        {
          state: 'certifications',
          name: 'Certifications',
          type: 'link',
          icon: 'descriptions-custom'
        },
        {
          state: 'product_category',
          name: 'Product Category',
          type: 'link',
          icon: 'cake-chart-custom'
        },
        {
          state: 'languages',
          name: 'Languages',
          type: 'link',
          icon: 'languages-custom'
        },
        {
          state: 'services_list',
          name: 'Company Services',
          type: 'link',
          icon: 'tech-custom'
        },
        {

          state: 'functions',
          name: 'Functions',
          type: 'link',
          icon: 'tech-custom'
        },
        {
          state: 'ghost_account',
          name: 'Ghost Account',
          type: 'link',
          icon: 'company-custom'
        },
        {
          state: 'configuration',
          name: 'Configuration',
          type: 'link',
          icon: 'tech-custom'
        }

      ];
    } else {
      ADMINMENUITEMS = [
        {
          state: 'company',
          name: 'Company',
          type: 'link',
          icon: 'company-custom'
        },
        {
          state: 'search_supplier',
          name: 'Search Company',
          type: 'link',
          icon: 'search-supplier'
        },
        {
          state: 'find_services',
          name: 'Find Services',
          type: 'link',
          icon: 'search-services',
        },
        {
          state: 'profile_available',
          name: 'Profile Available',
          type: 'link',
          icon: 'available-profiles-custom'
        },
        {
          state: 'position_list',
          name: 'Position List',
          type: 'link',
          icon: 'open-position-custom'
        },
        {
          state: 'technology',
          name: 'Technology',
          type: 'link',
          icon: 'tech-custom'
        },
        {
          state: 'job_sector',
          name: 'Job Sector',
          type: 'link',
          icon: 'cake-chart-custom'
        },
        {
          state: 'description_pages',
          name: 'Description Pages',
          type: 'link',
          icon: 'descriptions-custom'
        },
        {
          state: 'email_notifications',
          name: 'Email Notifications',
          type: 'link',
          icon: 'bell-custom'
        },
        {
          state: 'certifications',
          name: 'Certifications',
          type: 'link',
          icon: 'descriptions-custom'
        },
        {
          state: 'product_category',
          name: 'Product Category',
          type: 'link',
          icon: 'cake-chart-custom'
        },
        {
          state: 'languages',
          name: 'Languages',
          type: 'link',
          icon: 'languages-custom'
        },
        {

          state: 'services_list',
          name: 'Company Services',
          type: 'link',
          icon: 'tech-custom'
        },
        {

          state: 'functions',
          name: 'Functions',
          type: 'link',
          icon: 'tech-custom'
        },
        {
          state: 'ghost_account',
          name: 'Ghost Account',
          type: 'link',
          icon: 'company-custom'
        },
        {
          state: 'configuration',
          name: 'Configuration',
          type: 'link',
          icon: 'tech-custom'
        }
      ];
    }

    var MENUITEMS: any = [];
    if (this.versionProduct == 2) {
      MENUITEMS = [

        {
          state: 'company',
          name: 'Company',
          type: 'link',
          icon: 'company-custom'
        },
        {
          state: 'search_supplier',
          name: 'Search Company',
          type: 'link',
          icon: 'search-supplier'
        },
        {
          state: 'find_services',
          name: 'Find Services',
          type: 'link',
          icon: 'search-services',
        },
        {
          state: 'profile_available',
          name: 'Profile Available',
          type: 'link',
          icon: 'available-profiles-custom'
        },
        {
          state: 'position_list',
          name: 'Position List',
          type: 'link',
          icon: 'open-position-custom'
        },
        {
          state: 'accademy_profiles',
          name: 'Profile Accademy',
          type: 'link',
          icon: 'academy-custom'
        },
        {
          state: 'collaborations_admin',
          name: 'Collaborations Admin',
          type: 'link',
          icon: 'tech-custom'
        },
        {
          state: 'plans',
          name: 'Plans',
          type: 'link',
          icon: 'plan-custom'
        },
        {
          state: 'plans_supplier',
          name: 'Plans Supplier',
          type: 'link',
          icon: 'plan-custom'
        },
        // {
        //   state: 'coupons',
        //   name: 'Coupons',
        //   type: 'link',
        //   icon: 'plan-custom'
        // },
        {
          state: 'technology',
          name: 'Technology',
          type: 'link',
          icon: 'tech-custom'
        },
        {
          state: 'job_sector',
          name: 'Job Sector',
          type: 'link',
          icon: 'cake-chart-custom'
        },

        {
          state: 'description_pages',
          name: 'Description Pages',
          type: 'link',
          icon: 'descriptions-custom'
        },
        {
          state: 'employee_review',
          name: 'Employee Review',
          type: 'link',
          icon: 'profile-review-custom'
        },
        {
          state: 'email_notifications',
          name: 'Email Notifications',
          type: 'link',
          icon: 'bell-custom'
        },

        {
          state: 'pending_inscriptions',
          name: 'Pending Inscriptions',
          type: 'link',
          icon: 'company-custom'
        },
        {
          state: 'certifications',
          name: 'Certifications',
          type: 'link',
          icon: 'descriptions-custom'
        },
        {
          state: 'product_category',
          name: 'Product Category',
          type: 'link',
          icon: 'cake-chart-custom'
        },
        {

          state: 'services_list',
          name: 'Company Services',
          type: 'link',
          icon: 'tech-custom'
        },
        {
          state: 'manage_admin_users',
          name: 'Manage Users Admin',
          type: 'link',
          icon: 'manage-user-custom'
        },
        {
          state: 'languages',
          name: 'Languages',
          type: 'link',
          icon: 'languages-custom'
        },
        {

          state: 'functions',
          name: 'Functions',
          type: 'link',
          icon: 'tech-custom'
        },
        {
          state: 'ghost_account',
          name: 'Ghost Account',
          type: 'link',
          icon: 'company-custom'
        },
        {
          state: 'configuration',
          name: 'Configuration',
          type: 'link',
          icon: 'tech-custom'
        }
      ];
    } else {
      MENUITEMS = [

        {
          state: 'company',
          name: 'Company',
          type: 'link',
          icon: 'company-custom'
        },
        {
          state: 'search_supplier',
          name: 'Search Company',
          type: 'link',
          icon: 'search-supplier'
        },
        {
          state: 'find_services',
          name: 'Find Services',
          type: 'link',
          icon: 'search-services',
        },
        {
          state: 'profile_available',
          name: 'Profile Available',
          type: 'link',
          icon: 'available-profiles-custom'
        },
        {
          state: 'position_list',
          name: 'Position List',
          type: 'link',
          icon: 'open-position-custom'
        },
        {
          state: 'technology',
          name: 'Technology',
          type: 'link',
          icon: 'tech-custom'
        },
        {
          state: 'job_sector',
          name: 'Job Sector',
          type: 'link',
          icon: 'cake-chart-custom'
        },
        {
          state: 'description_pages',
          name: 'Description Pages',
          type: 'link',
          icon: 'descriptions-custom'
        },
        {
          state: 'email_notifications',
          name: 'Email Notifications',
          type: 'link',
          icon: 'bell-custom'
        },
        {
          state: 'certifications',
          name: 'Certifications',
          type: 'link',
          icon: 'descriptions-custom'
        },
        {
          state: 'product_category',
          name: 'Product Category',
          type: 'link',
          icon: 'cake-chart-custom'
        },
        {

          state: 'services_list',
          name: 'Company Services',
          type: 'link',
          icon: 'tech-custom'
        },
        {
          state: 'manage_admin_users',
          name: 'Manage Users Admin',
          type: 'link',
          icon: 'manage-user-custom'
        },
        {
          state: 'languages',
          name: 'Languages',
          type: 'link',
          icon: 'languages-custom'
        },
        {

          state: 'functions',
          name: 'Functions',
          type: 'link',
          icon: 'tech-custom'
        },
        {
          state: 'ghost_account',
          name: 'Ghost Account',
          type: 'link',
          icon: 'company-custom'
        },
        {
          state: 'configuration',
          name: 'Configuration',
          type: 'link',
          icon: 'tech-custom'
        }
      ];
    }

    // VOCI MENU CLIENTE
    var CLIENTMENUITEMS: any = [];

    // SE LA VERSIONE E MARKETPLACE
    if (this.versionProduct == 2) {

      CLIENTMENUITEMS = [];

      for (let i = 0; i < this.functions.length; i++) {
        switch (this.functions[i]['state']) {
          case 'dashboard_client':
            if (this.functions[i]['status'] == 1) {
              CLIENTMENUITEMS.push({
                state: 'dashboard_client',
                name: 'Dashboards',
                type: 'link',
                icon: 'dashboard-custom',
                priority: 1
              })
            }
            break;
          case 'search_partner':
            // if(this.functions[i]['status'] == 1){
            // CLIENTMENUITEMS.push({
            //   state: 'search_supplier',
            //   name: 'Search Supplier',
            //   type: 'link',
            //   icon: 'search-supplier',
            //   priority: 2
            // })
            // }
            break;
          case 'need':
            if (this.functions[i]['status'] == 1) {
              CLIENTMENUITEMS.push({
                state: 'need',
                name: 'Need',
                type: 'link',
                icon: 'search-profile-custom',
                priority: 3
              })
            }
            break;
          case 'profile_available':
            if (this.functions[i]['status'] == 1) {
              CLIENTMENUITEMS.push({
                state: 'profile_available',
                name: 'Profile Available',
                type: 'link',
                icon: 'available-profiles-custom',
                priority: 4
              })
            }
            break;
          case 'accademy_profiles':
            // if(this.functions[i]['status'] == 1){
            CLIENTMENUITEMS.push({
              state: 'accademy_profiles',
              name: 'Profile Accademy',
              type: 'link',
              icon: 'academy-custom',
              priority: 5
            })
            // }
            break;
          case 'saved_profiles':
            if (this.functions[i]['status'] == 1) {
              CLIENTMENUITEMS.push({
                state: 'saved_profiles',
                name: 'Saved Profiles',
                type: 'link',
                icon: 'favourites-custom',
                priority: 6
              })
            }
            break;
          case 'collaborations_invite':
            if (this.functions[i]['status'] == 1) {
              CLIENTMENUITEMS.push({
                state: 'collaborations_invite',
                name: 'Collaborations Invite',
                type: 'link',
                icon: 'collaborations-custom',
                priority: 7
              })
            }
            break;
          // case 'supplier_profile':
          //     if(this.functions[i]['status'] == 1){
          //       CLIENTMENUITEMS.push({
          //         state: 'supplier_profile',
          //         name: 'Supplier List',
          //         type: 'link',
          //         icon: 'supplier-custom',
          //         priority: 8
          //       })
          //     }
          //   break;

          default:
            break;
        }
      }

      CLIENTMENUITEMS.push({
        state: 'search_supplier',
        name: 'Search Supplier',
        type: 'link',
        icon: 'search-supplier',
        priority: 2
      })

      CLIENTMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

    } else {
      // SE LA VERSIONE E WL
      CLIENTMENUITEMS = [];
      // SE LA VERSIONE E WL E this.currentUser.domain_url != null && this.currentUser.n_max_supplier != null
      if (this.currentUser && this.currentUser.domain_url != null && this.currentUser.n_max_supplier != null) {

        CLIENTMENUITEMS = [];

        for (let i = 0; i < this.functions.length; i++) {
          switch (this.functions[i]['state']) {
            case 'dashboard_client':
              if (this.functions[i]['status'] == 1) {
                CLIENTMENUITEMS.push({
                  state: 'dashboard_client',
                  name: 'Dashboards',
                  type: 'link',
                  icon: 'dashboard-custom',
                  priority: 1
                })
              }
              break;
            case 'search_partner':
              if (this.functions[i]['status'] == 1) {
                CLIENTMENUITEMS.push({
                  state: 'search_supplier',
                  name: 'Search Supplier',
                  type: 'link',
                  icon: 'search-supplier',
                  priority: 2
                })
              }
              break;
            case 'need':
              if (this.functions[i]['status'] == 1) {
                CLIENTMENUITEMS.push({
                  state: 'need',
                  name: 'Need',
                  type: 'link',
                  icon: 'search-profile-custom',
                  priority: 3
                })
              }
              break;
            case 'profile_available':
              if (this.functions[i]['status'] == 1) {
                CLIENTMENUITEMS.push({
                  state: 'profile_available',
                  name: 'Profile Available',
                  type: 'link',
                  icon: 'available-profiles-custom',
                  priority: 4
                })
              }
              break;
            case 'saved_profiles':
              if (this.functions[i]['status'] == 1) {
                CLIENTMENUITEMS.push({
                  state: 'saved_profiles',
                  name: 'Saved Profiles',
                  type: 'link',
                  icon: 'favourites-custom',
                  priority: 5
                })
              }
              break;
            case 'collaborations_invite':
              if (this.functions[i]['status'] == 1) {
                CLIENTMENUITEMS.push({
                  state: 'collaborations_invite',
                  name: 'Collaborations Invite',
                  type: 'link',
                  icon: 'collaborations-custom',
                  priority: 6
                })
              }
              break;
            case 'supplier_profile':
              if (this.functions[i]['status'] == 1) {
                if (window.location.origin == this.currentUser.domain_url) {
                  CLIENTMENUITEMS.push({
                    state: 'supplier_profile',
                    name: 'Supplier List',
                    type: 'link',
                    icon: 'supplier-custom',
                    priority: 7
                  })
                }

              }
              break;

            default:
              break;
          }
        }

        CLIENTMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

      } else {
        // SE LA VERSIONE E WL E this.currentUser.domain_url == null || this.currentUser.n_max_supplier == null
        CLIENTMENUITEMS = [];
        // SE LA VERSIONE E WL E this.currentUser.domain_url == null || this.currentUser.n_max_supplier == null && non è un academy
        if (this.currentUser && this.currentUser.simulate_academy == 0) {

          CLIENTMENUITEMS = [];

          for (let i = 0; i < this.functions.length; i++) {
            switch (this.functions[i]['state']) {
              case 'dashboard_client':
                if (this.functions[i]['status'] == 1) {
                  CLIENTMENUITEMS.push({
                    state: 'dashboard_client',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom',
                    priority: 1
                  })
                }
                break;
              case 'need':
                if (this.functions[i]['status'] == 1) {
                  CLIENTMENUITEMS.push({
                    state: 'need',
                    name: 'Need',
                    type: 'link',
                    icon: 'search-profile-custom',
                    priority: 2
                  })
                }
                break;
              case 'profile_available':
                if (this.functions[i]['status'] == 1) {
                  CLIENTMENUITEMS.push({
                    state: 'profile_available',
                    name: 'Profile Available',
                    type: 'link',
                    icon: 'available-profiles-custom',
                    priority: 3
                  })
                }
                break;
              case 'saved_profiles':
                if (this.functions[i]['status'] == 1) {
                  CLIENTMENUITEMS.push({
                    state: 'saved_profiles',
                    name: 'Saved Profiles',
                    type: 'link',
                    icon: 'favourites-custom',
                    priority: 4
                  })
                }
                break;
              case 'collaborations_invite':
                if (this.functions[i]['status'] == 1) {
                  CLIENTMENUITEMS.push({
                    state: 'collaborations_invite',
                    name: 'Collaborations Invite',
                    type: 'link',
                    icon: 'collaborations-custom',
                    priority: 5
                  })
                }
                break;

              default:
                break;
            }
          }

          CLIENTMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

        } else if (this.currentUser && this.currentUser.simulate_academy == 1) {
          // SE LA VERSIONE E WL E this.currentUser.domain_url == null || this.currentUser.n_max_supplier == null &&  è un academy
          CLIENTMENUITEMS = [];

          for (let i = 0; i < this.functions.length; i++) {
            switch (this.functions[i]['state']) {
              case 'dashboard_client':
                if (this.functions[i]['status'] == 1) {
                  CLIENTMENUITEMS.push({
                    state: 'dashboard_client',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom',
                    priority: 1
                  })
                }
                break;
              case 'profile_available':
                if (this.functions[i]['status'] == 1) {
                  CLIENTMENUITEMS.push({
                    state: 'profile_available',
                    name: 'Profile Available',
                    type: 'link',
                    icon: 'available-profiles-custom',
                    priority: 2
                  })
                }
                break;
              case 'saved_profiles':
                if (this.functions[i]['status'] == 1) {
                  CLIENTMENUITEMS.push({
                    state: 'saved_profiles',
                    name: 'Saved Profiles',
                    type: 'link',
                    icon: 'favourites-custom',
                    priority: 3
                  })
                }
                break;
              case 'collaborations_invite':
                if (this.functions[i]['status'] == 1) {
                  CLIENTMENUITEMS.push({
                    state: 'collaborations_invite',
                    name: 'Collaborations Invite',
                    type: 'link',
                    icon: 'collaborations-custom',
                    priority: 4
                  })
                }
                break;

              default:
                break;
            }
          }

          CLIENTMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

        }

      }

    }
    // VOCI MENU ADMIN CLIENTE
    var CLIENTEMPLOYEEMENUITEMS: any = [];

    // SE E MARKETPLACE
    if (this.versionProduct == 2) {

      for (let i = 0; i < this.functions.length; i++) {
        switch (this.functions[i]['state']) {
          case 'dashboard_client':
            if (this.functions[i]['status'] == 1) {
              CLIENTEMPLOYEEMENUITEMS.push({
                state: 'dashboard_client',
                name: 'Dashboards',
                type: 'link',
                icon: 'dashboard-custom',
                priority: 1
              })
            }
            break;
          case 'search_partner':
            // if(this.functions[i]['status'] == 1){
            // CLIENTEMPLOYEEMENUITEMS.push({
            //   state: 'search_supplier',
            //   name: 'Search Supplier',
            //   type: 'link',
            //   icon: 'search-supplier',
            //   priority: 2
            // })
            // }
            break;
          case 'need':
            if (this.functions[i]['status'] == 1) {
              CLIENTEMPLOYEEMENUITEMS.push({
                state: 'need',
                name: 'Need',
                type: 'link',
                icon: 'search-profile-custom',
                priority: 3
              })
            }
            break;
          case 'profile_available':
            if (this.functions[i]['status'] == 1) {
              CLIENTEMPLOYEEMENUITEMS.push({
                state: 'profile_available',
                name: 'Profile Available',
                type: 'link',
                icon: 'available-profiles-custom',
                priority: 4
              })
            }
            break;
          case 'accademy_profiles':
            // if(this.functions[i]['status'] == 1){
            CLIENTEMPLOYEEMENUITEMS.push({
              state: 'accademy_profiles',
              name: 'Profile Accademy',
              type: 'link',
              icon: 'academy-custom',
              priority: 5
            })
            // }
            break;
          case 'saved_profiles':
            if (this.functions[i]['status'] == 1) {
              CLIENTEMPLOYEEMENUITEMS.push({
                state: 'saved_profiles',
                name: 'Saved Profiles',
                type: 'link',
                icon: 'favourites-custom',
                priority: 6
              })
            }
            break;
          case 'collaborations_invite':
            if (this.functions[i]['status'] == 1) {
              CLIENTEMPLOYEEMENUITEMS.push({
                state: 'collaborations_invite',
                name: 'Collaborations Invite',
                type: 'link',
                icon: 'collaborations-custom',
                priority: 7
              })
            }
            break;
            // case 'supplier_profile':
            //     if(this.functions[i]['status'] == 1){
            //       CLIENTEMPLOYEEMENUITEMS.push({
            //         state: 'supplier_profile',
            //         name: 'Supplier List',
            //         type: 'link',
            //         icon: 'supplier-custom',
            //         priority: 8
            //       })
            //     }
            break;
          default:
            break;
        }
      }

      CLIENTEMPLOYEEMENUITEMS.push({
        state: 'search_supplier',
        name: 'Search Supplier',
        type: 'link',
        icon: 'search-supplier',
        priority: 2
      })

      CLIENTEMPLOYEEMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

      // SE E WL
    } else {

      // SE E WL && && this.currentUser.domain_url != null && this.currentUser.n_max_supplier != null
      if (this.currentUser && this.currentUser.domain_url != null && this.currentUser.n_max_supplier != null) {
        for (let i = 0; i < this.functions.length; i++) {
          switch (this.functions[i]['state']) {
            case 'dashboard_client':
              if (this.functions[i]['status'] == 1) {
                CLIENTEMPLOYEEMENUITEMS.push({
                  state: 'dashboard_client',
                  name: 'Dashboards',
                  type: 'link',
                  icon: 'dashboard-custom',
                  priority: 1
                })
              }
              break;
            case 'search_partner':
              if (this.functions[i]['status'] == 1) {
                CLIENTEMPLOYEEMENUITEMS.push({
                  state: 'search_supplier',
                  name: 'Search Supplier',
                  type: 'link',
                  icon: 'search-supplier',
                  priority: 2
                })
              }
              break;
            case 'need':
              if (this.functions[i]['status'] == 1) {
                CLIENTEMPLOYEEMENUITEMS.push({
                  state: 'need',
                  name: 'Need',
                  type: 'link',
                  icon: 'search-profile-custom',
                  priority: 3
                })
              }
              break;
            case 'profile_available':
              if (this.functions[i]['status'] == 1) {
                CLIENTEMPLOYEEMENUITEMS.push({
                  state: 'profile_available',
                  name: 'Profile Available',
                  type: 'link',
                  icon: 'available-profiles-custom',
                  priority: 4
                })
              }
              break;
            case 'saved_profiles':
              if (this.functions[i]['status'] == 1) {
                CLIENTEMPLOYEEMENUITEMS.push({
                  state: 'saved_profiles',
                  name: 'Saved Profiles',
                  type: 'link',
                  icon: 'favourites-custom',
                  priority: 5
                })
              }
              break;
            case 'collaborations_invite':
              if (this.functions[i]['status'] == 1) {
                CLIENTEMPLOYEEMENUITEMS.push({
                  state: 'collaborations_invite',
                  name: 'Collaborations Invite',
                  type: 'link',
                  icon: 'collaborations-custom',
                  priority: 6
                })
              }
              break;
            case 'supplier_profile':
              if (this.functions[i]['status'] == 1) {
                if (window.location.origin == this.currentUser.domain_url) {

                  CLIENTEMPLOYEEMENUITEMS.push({
                    state: 'supplier_profile',
                    name: 'Supplier List',
                    type: 'link',
                    icon: 'supplier-custom',
                    priority: 7
                  })
                }
              }
              break;

            default:
              break;
          }
        }

        CLIENTEMPLOYEEMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

        // SE E WL && && this.currentUser.domain_url == null || this.currentUser.n_max_supplier == null

      } else {

        // SE E WL && && this.currentUser.domain_url == null || this.currentUser.n_max_supplier == null && non è academy
        if (this.currentUser && this.currentUser.simulate_academy == 0) {
          for (let i = 0; i < this.functions.length; i++) {
            switch (this.functions[i]['state']) {
              case 'dashboard_client':
                if (this.functions[i]['status'] == 1) {
                  CLIENTEMPLOYEEMENUITEMS.push({
                    state: 'dashboard_client',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom',
                    priority: 1
                  })
                }
                break;
              case 'need':
                if (this.functions[i]['status'] == 1) {
                  CLIENTEMPLOYEEMENUITEMS.push({
                    state: 'need',
                    name: 'Need',
                    type: 'link',
                    icon: 'search-profile-custom',
                    priority: 2
                  })
                }
                break;
              case 'profile_available':
                if (this.functions[i]['status'] == 1) {
                  CLIENTEMPLOYEEMENUITEMS.push({
                    state: 'profile_available',
                    name: 'Profile Available',
                    type: 'link',
                    icon: 'available-profiles-custom',
                    priority: 3
                  })
                }
                break;
              case 'saved_profiles':
                if (this.functions[i]['status'] == 1) {
                  CLIENTEMPLOYEEMENUITEMS.push({
                    state: 'saved_profiles',
                    name: 'Saved Profiles',
                    type: 'link',
                    icon: 'favourites-custom',
                    priority: 4
                  })
                }
                break;
              case 'collaborations_invite':
                if (this.functions[i]['status'] == 1) {
                  CLIENTEMPLOYEEMENUITEMS.push({
                    state: 'collaborations_invite',
                    name: 'Collaborations Invite',
                    type: 'link',
                    icon: 'collaborations-custom',
                    priority: 5
                  })
                }
                break;
              default:
                break;
            }
          }

          CLIENTEMPLOYEEMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

        } else if (this.currentUser && this.currentUser.simulate_academy == 1) {
          // SE E WL && && this.currentUser.domain_url == null || this.currentUser.n_max_supplier == null && è academy

          for (let i = 0; i < this.functions.length; i++) {
            switch (this.functions[i]['state']) {
              case 'dashboard_client':
                if (this.functions[i]['status'] == 1) {
                  CLIENTEMPLOYEEMENUITEMS.push({
                    state: 'dashboard_client',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom',
                    priority: 1
                  })
                }
                break;
              case 'profile_available':
                if (this.functions[i]['status'] == 1) {
                  CLIENTEMPLOYEEMENUITEMS.push({
                    state: 'profile_available',
                    name: 'Profile Available',
                    type: 'link',
                    icon: 'available-profiles-custom',
                    priority: 2
                  })
                }
                break;
              case 'saved_profiles':
                if (this.functions[i]['status'] == 1) {
                  CLIENTEMPLOYEEMENUITEMS.push({
                    state: 'saved_profiles',
                    name: 'Saved Profiles',
                    type: 'link',
                    icon: 'favourites-custom',
                    priority: 3
                  })
                }
                break;
              case 'collaborations_invite':
                if (this.functions[i]['status'] == 1) {
                  CLIENTEMPLOYEEMENUITEMS.push({
                    state: 'collaborations_invite',
                    name: 'Collaborations Invite',
                    type: 'link',
                    icon: 'collaborations-custom',
                    priority: 4
                  })
                }
                break;
              default:
                break;
            }
          }

          CLIENTEMPLOYEEMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

        }

      }

    }

    // MENU ITEMS FORNITORE
    var SUPPLIERMENUITEMS: any = [];

    // SE E MARKETPLACE
    if (this.versionProduct == 2) {

      for (let i = 0; i < this.functions.length; i++) {
        switch (this.functions[i]['state']) {
          case 'dashboard_supplier':
            if (this.functions[i]['status'] == 1) {
              SUPPLIERMENUITEMS.push({
                state: 'dashboard_supplier',
                name: 'Dashboards',
                type: 'link',
                icon: 'dashboard-custom',
                priority: 1
              })
            }
            break;
          case 'search_partner':
            // if (this.functions[i]['status'] == 1) {
            // SUPPLIERMENUITEMS.push({
            //   state: 'search_supplier',
            //   name: 'Search Partner',
            //   type: 'link',
            //   icon: 'search-supplier',
            //   priority: 2
            // })
            // }
            break;
          case 'company_profile_supplier':
            if (this.functions[i]['status'] == 1) {
              SUPPLIERMENUITEMS.push({
                state: 'company_profile_supplier',
                name: 'Company Profile',
                type: 'link',
                icon: 'company-profile-custom',
                priority: 3
              })
            }
            break;
          case 'position_list':
            if (this.functions[i]['status'] == 1) {
              SUPPLIERMENUITEMS.push({
                state: 'position_list',
                name: 'Position List',
                type: 'link',
                icon: 'open-position-custom',
                priority: 4
              })
            }
            break;
          // case 'accademy_profiles':
          //     // if(this.functions[i]['status'] == 1){
          //       SUPPLIERMENUITEMS.push({
          //         state: 'accademy_profiles',
          //         name: 'Profile Accademy',
          //         type: 'link',
          //         icon: 'academy-custom',
          //         priority: 5
          //       })
          //     // }
          //   break;
          case 'employees':
            if (this.functions[i]['status'] == 1) {
              SUPPLIERMENUITEMS.push({
                state: 'employees',
                name: 'Employees',
                type: 'link',
                icon: 'available-profiles-custom',
                priority: 6
              })
            }
            break;
          case 'collaborations_request':
            if (this.functions[i]['status'] == 1) {
              SUPPLIERMENUITEMS.push({
                state: 'collaborations_request',
                name: 'Collaborations Request',
                type: 'link',
                icon: 'collaborations-custom',
                priority: 7
              })
            }
            break;
          // case 'customer_profile':
          //     if(this.functions[i]['status'] == 1){
          //       SUPPLIERMENUITEMS.push({
          //         state: 'customer_profile',
          //         name: 'Customer Profile',
          //         type: 'link',
          //         icon: 'job-custom',
          //         priority: 7
          //       })
          //     }
          //   break;
          // case 'digitize_cv':
          //     if(this.functions[i]['status'] == 1){
          //       SUPPLIERMENUITEMS.push({
          //         state: 'digitize_cv',
          //         name: 'Digitize CV',
          //         type: 'link',
          //         icon: 'cv-custom',
          //         priority: 8
          //       })
          //     }
          //   break;

          default:
            break;
        }
      }

      SUPPLIERMENUITEMS.push({
        state: 'search_supplier',
        name: 'Search Partner',
        type: 'link',
        icon: 'search-supplier',
        priority: 2
      })

      SUPPLIERMENUITEMS.push({
        state: 'accademy_profiles',
        name: 'Profile Accademy',
        type: 'link',
        icon: 'academy-custom',
        priority: 5
      })

      SUPPLIERMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

    } else {
      // SE E WL

      // SE E WL E this.currentUser.domain_url != null && this.currentUser.n_max_supplier != null
      if (this.currentUser && this.currentUser.domain_url != null && this.currentUser.n_max_supplier != null) {

        // SE E WL E this.currentUser.domain_url != null && this.currentUser.n_max_supplier != null && E non e academy
        if (this.currentUser && this.currentUser.simulate_academy == 0) {
          for (let i = 0; i < this.functions.length; i++) {
            switch (this.functions[i]['state']) {
              case 'dashboard_supplier':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom',
                    priority: 1
                  })
                }
                break;
              case 'search_partner':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'search_supplier',
                    name: 'Search Partner',
                    type: 'link',
                    icon: 'search-supplier',
                    priority: 2
                  })
                }
                break;
              case 'company_profile_supplier':
                if (this.functions[i]['status'] == 1) {
                  if (this.currentUser.work_force == 0) {
                    SUPPLIERMENUITEMS.push({
                      state: 'company_profile_supplier',
                      name: 'Company Profile',
                      type: 'link',
                      icon: 'company-profile-custom',
                      priority: 3
                    })
                  }
                }
                break;
              case 'position_list':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'position_list',
                    name: 'Position List',
                    type: 'link',
                    icon: 'open-position-custom',
                    priority: 4
                  })
                }
                break;
              case 'employees':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom',
                    priority: 5
                  })
                }
                break;
              case 'collaborations_request':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom',
                    priority: 6
                  })
                }
                break;
              case 'customer_profile':
                if (this.functions[i]['status'] == 1) {
                  if (window.location.origin == this.currentUser.domain_url) {
                    SUPPLIERMENUITEMS.push({
                      state: 'customer_profile',
                      name: 'Customer Profile',
                      type: 'link',
                      icon: 'job-custom',
                      priority: 7
                    })
                  }
                }
                break;

              default:
                break;
            }
          }

          SUPPLIERMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

        } else if (this.currentUser && this.currentUser.simulate_academy == 1) {
          // SE E WL E this.currentUser.domain_url != null && this.currentUser.n_max_supplier != null && e academy
          for (let i = 0; i < this.functions.length; i++) {
            switch (this.functions[i]['state']) {
              case 'company_profile_supplier':
                if (this.functions[i]['status'] == 1) {
                  if (this.currentUser.work_force == 0) {
                    SUPPLIERMENUITEMS.push({
                      state: 'company_profile_supplier',
                      name: 'Company Profile',
                      type: 'link',
                      icon: 'company-profile-custom',
                      priority: 2
                    })
                  }
                }
                break;
              case 'dashboard_supplier':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'dashboard_academy',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom',
                    priority: 1
                  })
                }
                break;
              case 'employees':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom',
                    priority: 3
                  })
                }
                break;
              case 'collaborations_request':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom',
                    priority: 4
                  })
                }
                break;
              case 'customer_profile':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'customer_profile',
                    name: 'Customer Profile',
                    type: 'link',
                    icon: 'job-custom',
                    priority: 5
                  })
                }
                break;
              case 'graphs':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'graphs',
                    name: 'Graphs',
                    type: 'link',
                    icon: 'metrics',
                    priority: 6
                  })
                }
                break;

              default:
                break;
            }
          }
        }

        SUPPLIERMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

        // SE E WL E this.currentUser.domain_url == null || this.currentUser.n_max_supplier == null
      } else {

        // SE E WL E this.currentUser.domain_url == null || this.currentUser.n_max_supplier == null && non è academy
        if (this.currentUser && this.currentUser.simulate_academy == 0) {

          for (let i = 0; i < this.functions.length; i++) {
            switch (this.functions[i]['state']) {
              case 'dashboard_supplier':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom',
                    priority: 1
                  })
                }
                break;
              case 'search_partner':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'search_supplier',
                    name: 'Search Partner',
                    type: 'link',
                    icon: 'search-supplier',
                    priority: 2
                  })
                }
                break;
              case 'company_profile_supplier':
                if (this.functions[i]['status'] == 1) {
                  if (this.currentUser.work_force == 0) {
                    SUPPLIERMENUITEMS.push({
                      state: 'company_profile_supplier',
                      name: 'Company Profile',
                      type: 'link',
                      icon: 'company-profile-custom',
                      priority: 3
                    })
                  }
                }
                break;
              case 'position_list':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'position_list',
                    name: 'Position List',
                    type: 'link',
                    icon: 'open-position-custom',
                    priority: 4
                  })
                }
                break;
              case 'employees':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom',
                    priority: 5
                  })
                }
                break;
              case 'collaborations_request':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom',
                    priority: 6
                  })
                }
                break;
              default:
                break;
            }
          }

          SUPPLIERMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

        } else if (this.currentUser && this.currentUser.simulate_academy == 1) {

          // SE E WL E this.currentUser.domain_url == null || this.currentUser.n_max_supplier == null &&  è academy

          for (let i = 0; i < this.functions.length; i++) {
            switch (this.functions[i]['state']) {
              case 'company_profile_supplier':
                if (this.functions[i]['status'] == 1) {
                  if (this.currentUser.work_force == 0) {
                    SUPPLIERMENUITEMS.push({
                      state: 'company_profile_supplier',
                      name: 'Company Profile',
                      type: 'link',
                      icon: 'company-profile-custom',
                      priority: 2
                    })
                  }
                }
                break;
              case 'dashboard_supplier':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'dashboard_academy',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom',
                    priority: 1
                  })
                }
                break;
              case 'employees':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom',
                    priority: 3
                  })
                }
                break;
              case 'collaborations_request':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom',
                    priority: 4
                  })
                }
                break;
              case 'customer_profile':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'customer_profile',
                    name: 'Customer Profile',
                    type: 'link',
                    icon: 'job-custom',
                    priority: 5
                  })
                }
                break;
              case 'graphs':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMENUITEMS.push({
                    state: 'graphs',
                    name: 'Graphs',
                    type: 'link',
                    icon: 'metrics',
                    priority: 6
                  })
                }
                break;

              default:
                break;
            }
          }

          SUPPLIERMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

        }

      }

    }
    // AIUTO AMMINISTRATORE FORNITORE ITEM MENU
    var SUPPLIERMANAGEMENMENUITEMS: any = [];

    // SE E MARKETPLACE
    if (this.versionProduct == 2) {

      for (let i = 0; i < this.functions.length; i++) {
        switch (this.functions[i]['state']) {
          case 'dashboard_supplier':
            if (this.functions[i]['status'] == 1) {
              SUPPLIERMANAGEMENMENUITEMS.push({
                state: 'dashboard_supplier',
                name: 'Dashboards',
                type: 'link',
                icon: 'dashboard-custom',
                priority: 1
              })
            }
            break;
          case 'search_partner':
            // if (this.functions[i]['status'] == 1) {
            // SUPPLIERMANAGEMENMENUITEMS.push({
            //   state: 'search_supplier',
            //   name: 'Search Partner',
            //   type: 'link',
            //   icon: 'search-supplier',
            //   priority: 2
            // })
            // }
            break;
          case 'position_list':
            if (this.functions[i]['status'] == 1) {
              SUPPLIERMANAGEMENMENUITEMS.push({
                state: 'position_list',
                name: 'Position List',
                type: 'link',
                icon: 'open-position-custom',
                priority: 3
              })
            }
            break;
          // case 'accademy_profiles':
          //     // if(this.functions[i]['status'] == 1){
          //       SUPPLIERMANAGEMENMENUITEMS.push({
          //         state: 'accademy_profiles',
          //         name: 'Profile Accademy',
          //         type: 'link',
          //         icon: 'academy-custom',
          //         priority: 4
          //       })
          //     // }
          //   break;
          case 'employees':
            if (this.functions[i]['status'] == 1) {
              SUPPLIERMANAGEMENMENUITEMS.push({
                state: 'employees',
                name: 'Employees',
                type: 'link',
                icon: 'available-profiles-custom',
                priority: 5
              })
            }
            break;
          case 'collaborations_request':
            if (this.functions[i]['status'] == 1) {
              SUPPLIERMANAGEMENMENUITEMS.push({
                state: 'collaborations_request',
                name: 'Collaborations Request',
                type: 'link',
                icon: 'collaborations-custom',
                priority: 6
              })
            }
            break;
          // case 'customer_profile':
          //     if(this.functions[i]['status'] == 1){
          //       SUPPLIERMANAGEMENMENUITEMS.push({
          //         state: 'customer_profile',
          //         name: 'Customer Profile',
          //         type: 'link',
          //         icon: 'job-custom',
          //         priority: 6
          //       })
          //     }
          //   break;
          // case 'digitize_cv':
          //     if(this.functions[i]['status'] == 1){
          //       SUPPLIERMANAGEMENMENUITEMS.push({
          //         state: 'digitize_cv',
          //         name: 'Digitize CV',
          //         type: 'link',
          //         icon: 'cv-custom',
          //         priority: 7
          //       })
          //     }
          //   break;

          default:
            break;
        }
      }

      SUPPLIERMANAGEMENMENUITEMS.push({
        state: 'search_supplier',
        name: 'Search Partner',
        type: 'link',
        icon: 'search-supplier',
        priority: 2
      })

      SUPPLIERMANAGEMENMENUITEMS.push({
        state: 'accademy_profiles',
        name: 'Profile Accademy',
        type: 'link',
        icon: 'academy-custom',
        priority: 4
      })

      SUPPLIERMANAGEMENMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

    } else {
      // SE E WL

      // SE E WL E this.currentUser.domain_url != null && this.currentUser.n_max_supplier != null
      if (this.currentUser && this.currentUser.domain_url != null && this.currentUser.n_max_supplier != null) {

        // SE E WL E this.currentUser.domain_url != null && this.currentUser.n_max_supplier != null E NON è ACADEMY
        if (this.currentUser && this.currentUser.simulate_academy == 0) {
          for (let i = 0; i < this.functions.length; i++) {
            switch (this.functions[i]['state']) {
              case 'dashboard_supplier':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom',
                    priority: 1
                  })
                }
                break;
              case 'search_partner':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'search_supplier',
                    name: 'Search Partner',
                    type: 'link',
                    icon: 'search-supplier',
                    priority: 2
                  })
                }
                break;
              case 'position_list':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'position_list',
                    name: 'Position List',
                    type: 'link',
                    icon: 'open-position-custom',
                    priority: 3
                  })
                }
                break;
              case 'employees':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom',
                    priority: 4
                  })
                }
                break;
              case 'collaborations_request':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom',
                    priority: 5
                  })
                }
                break;
              case 'customer_profile':
                if (this.functions[i]['status'] == 1) {
                  if (window.location.origin == this.currentUser.domain_url) {

                    SUPPLIERMANAGEMENMENUITEMS.push({
                      state: 'customer_profile',
                      name: 'Customer Profile',
                      type: 'link',
                      icon: 'job-custom',
                      priority: 6
                    })
                  }
                }
                break;

              default:
                break;
            }
          }

          SUPPLIERMANAGEMENMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

          // SE E WL E this.currentUser.domain_url != null && this.currentUser.n_max_supplier != null E NON è ACademy
        } else if (this.currentUser && this.currentUser.simulate_academy == 0) {

          for (let i = 0; i < this.functions.length; i++) {
            switch (this.functions[i]['state']) {
              case 'dashboard_supplier':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom',
                    priority: 1
                  })
                }
                break;
              case 'search_partner':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'search_supplier',
                    name: 'Search Partner',
                    type: 'link',
                    icon: 'search-supplier',
                    priority: 2
                  })
                }
                break;
              case 'employees':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom',
                    priority: 3
                  })
                }
                break;
              case 'collaborations_request':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom',
                    priority: 4
                  })
                }
                break;
              case 'customer_profile':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'customer_profile',
                    name: 'Customer Profile',
                    type: 'link',
                    icon: 'job-custom',
                    priority: 5
                  })
                }
                break;

              default:
                break;
            }
          }

          SUPPLIERMANAGEMENMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

        }
        // SE E WL E this.currentUser.domain_url == null && this.currentUser.n_max_supplier == null
      } else {
        // SE E WL E this.currentUser.domain_url == null && this.currentUser.n_max_supplier == null E NON é ACADEMY
        if (this.currentUser && this.currentUser.simulate_academy == 0) {

          for (let i = 0; i < this.functions.length; i++) {
            switch (this.functions[i]['state']) {
              case 'dashboard_supplier':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom',
                    priority: 1
                  })
                }
                break;
              case 'search_partner':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'search_supplier',
                    name: 'Search Partner',
                    type: 'link',
                    icon: 'search-supplier',
                    priority: 2
                  })
                }
                break;
              case 'position_list':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'position_list',
                    name: 'Position List',
                    type: 'link',
                    icon: 'open-position-custom',
                    priority: 3
                  })
                }
                break;
              case 'employees':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom',
                    priority: 4
                  })
                }
                break;
              case 'collaborations_request':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom',
                    priority: 5
                  })
                }
                break;

              default:
                break;
            }
          }

          SUPPLIERMANAGEMENMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

          // SE E WL E this.currentUser.domain_url == null && this.currentUser.n_max_supplier == null ED E ACADEMY
        } else if (this.currentUser && this.currentUser.simulate_academy == 1) {
          for (let i = 0; i < this.functions.length; i++) {
            switch (this.functions[i]['state']) {
              case 'dashboard_supplier':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'dashboard_supplier',
                    name: 'Dashboards',
                    type: 'link',
                    icon: 'dashboard-custom',
                    priority: 1
                  })
                }
                break;
              case 'search_partner':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'search_supplier',
                    name: 'Search Partner',
                    type: 'link',
                    icon: 'search-supplier',
                    priority: 2
                  })
                }
                break;
              case 'employees':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'employees',
                    name: 'Employees',
                    type: 'link',
                    icon: 'available-profiles-custom',
                    priority: 3
                  })
                }
                break;
              case 'collaborations_request':
                if (this.functions[i]['status'] == 1) {
                  SUPPLIERMANAGEMENMENUITEMS.push({
                    state: 'collaborations_request',
                    name: 'Collaborations Request',
                    type: 'link',
                    icon: 'collaborations-custom',
                    priority: 4
                  })
                }
                break;

              default:
                break;
            }
          }

          SUPPLIERMANAGEMENMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

        }

      }

    }

    var ACCADEMYMENUITEMS: any = [];
    if (this.versionProduct == 2) {

      for (let i = 0; i < this.functions.length; i++) {
        switch (this.functions[i]['state']) {
          case 'dashboard_supplier':
            if (this.functions[i]['status'] == 1) {
              ACCADEMYMENUITEMS.push({
                state: 'dashboard_supplier',
                name: 'Dashboards',
                type: 'link',
                icon: 'dashboard-custom',
                priority: 1
              })
            }
            break;
          case 'company_profile_supplier':
            if (this.functions[i]['status'] == 1) {
              ACCADEMYMENUITEMS.push({
                state: 'company_profile_supplier',
                name: 'Company Profile',
                type: 'link',
                icon: 'company-profile-custom',
                priority: 2
              })
            }
            break;
          case 'employees':
            if (this.functions[i]['status'] == 1) {
              ACCADEMYMENUITEMS.push({
                state: 'employees',
                name: 'Employees',
                type: 'link',
                icon: 'available-profiles-custom',
                priority: 3
              })
            }
            break;
          case 'manage_users_supplier':
            if (this.functions[i]['status'] == 1) {
              ACCADEMYMENUITEMS.push({
                state: 'manage_users_supplier',
                name: 'Manage Users Supplier',
                type: 'link',
                icon: 'manage-user-custom',
                priority: 4
              })
            }
            break;
          default:
            break;
        }
      }
    } else {
      for (let i = 0; i < this.functions.length; i++) {
        switch (this.functions[i]['state']) {
          case 'dashboard_supplier':
            if (this.functions[i]['status'] == 1) {
              ACCADEMYMENUITEMS.push({
                state: 'dashboard_academy',
                name: 'Dashboards',
                type: 'link',
                icon: 'dashboard-custom',
                priority: 1
              })
            }
            break;
          case 'company_profile_supplier':
            if (this.functions[i]['status'] == 1) {
              ACCADEMYMENUITEMS.push({
                state: 'company_profile_supplier',
                name: 'Company Profile',
                type: 'link',
                icon: 'company-profile-custom',
                priority: 2
              })
            }
            break;
          case 'employees':
            if (this.functions[i]['status'] == 1) {
              ACCADEMYMENUITEMS.push({
                state: 'employees',
                name: 'Employees',
                type: 'link',
                icon: 'available-profiles-custom',
                priority: 3
              })
            }
            break;
          case 'manage_users_supplier':
            if (this.functions[i]['status'] == 1) {
              ACCADEMYMENUITEMS.push({
                state: 'manage_users_supplier',
                name: 'Manage Users Supplier',
                type: 'link',
                icon: 'manage-user-custom',
                priority: 4
              })
            }
            break;
          default:
            break;
        }
      }
    }

    ACCADEMYMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

    var MANAGEMENTACCADEMYMENUITEMS: any = [];
    for (let i = 0; i < this.functions.length; i++) {
      switch (this.functions[i]['state']) {
        case 'dashboard_supplier':
          if (this.functions[i]['status'] == 1) {
            MANAGEMENTACCADEMYMENUITEMS.push({
              state: 'dashboard_academy',
              name: 'Dashboards',
              type: 'link',
              icon: 'dashboard-custom',
              priority: 1
            })
          }
          break;
        case 'employees':
          if (this.functions[i]['status'] == 1) {
            MANAGEMENTACCADEMYMENUITEMS.push({
              state: 'employees',
              name: 'Employees',
              type: 'link',
              icon: 'available-profiles-custom',
              priority: 2
            })
          }
          break;
        default:
          break;
      }
    }

    MANAGEMENTACCADEMYMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

    var FREELANCEMENUITEMS: any = [];
    for (let i = 0; i < this.functions.length; i++) {
      switch (this.functions[i]['state']) {
        case 'dashboard_freelance':
          if (this.functions[i]['status'] == 1) {
            FREELANCEMENUITEMS.push({
              state: 'dashboard_freelance',
              name: 'Dashboards',
              type: 'link',
              icon: 'dashboard-custom',
              priority: 1
            })
          }
          break;
        case 'freelance_profile':
          if (this.functions[i]['status'] == 1) {
            FREELANCEMENUITEMS.push({
              state: 'freelance_profile',
              name: 'Freelance Profile',
              type: 'link',
              icon: 'freelance-profile-custom',
              priority: 2
            })
          }
          break;
        case 'freelance_position_list':
          if (this.functions[i]['status'] == 1) {
            FREELANCEMENUITEMS.push({
              state: 'freelance_position_list',
              name: 'Position List',
              type: 'link',
              icon: 'open-position-custom',
              priority: 3
            })
          }
          break;
        case 'collaborations_request':
          if (this.functions[i]['status'] == 1) {
            FREELANCEMENUITEMS.push({
              state: 'collaborations_request',
              name: 'Collaborations Request',
              type: 'link',
              icon: 'collaborations-custom',
              priority: 4
            })
          }
          break;
        default:
          break;
      }
    }

    FREELANCEMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

    let EMPLOYEEMENUITEMS: any;

    if (this.versionProduct == 1 && this.currentUser && this.currentUser.simulate_academy == 1 && this.currentUser.logicRole == 'studentAcademyLazy') {
      EMPLOYEEMENUITEMS = [
        {
          state: 'student_dashboard',
          name: 'Student Dashboard',
          type: 'link',
          icon: 'dashboard-custom'
        },
        {
          state: 'student_profile',
          name: 'Student Profile',
          type: 'link',
          icon: 'freelance-profile-custom'
        },
        {
          state: 'student_collaborations',
          name: 'Student Collaborations',
          type: 'link',
          icon: 'collaborations-custom'
        },
      ];
    } else {
      EMPLOYEEMENUITEMS = [
        // {
        //   state: 'student_dashboard',
        //   name: 'Student Dashboard',
        //   type: 'link',
        //   icon: 'dashboard-custom'
        // },
        // {
        //   state: 'student_profile',
        //   name: 'Student Profile',
        //   type: 'link',
        //   icon: 'freelance-profile-custom'
        // },
        // {
        //   state: 'student_collaborations',
        //   name: 'Student Collaborations',
        //   type: 'link',
        //   icon: 'collaborations-custom'
        // },
        {
          state: 'working_experience',
          name: 'Working Experience',
          type: 'link',
          icon: 'open-position-custom'
        },
        {
          state: 'upload_cv',
          name: 'Upload CV',
          type: 'link',
          icon: 'upload-cv-custom'
        },
      ];
    }

    let PROFILEACCADEMYMENUITEMS: any;

    if (this.versionProduct == 1 && this.currentUser.logicRole == 'studentAcademyLazy') {
      //console.log(this.currentUser.role);

      PROFILEACCADEMYMENUITEMS = [
        {
          state: 'student_dashboard',
          name: 'Student Dashboard',
          type: 'link',
          icon: 'dashboard-custom'
        },
        {
          state: 'student_profile',
          name: 'Student Profile',
          type: 'link',
          icon: 'freelance-profile-custom'
        },
        {
          state: 'student_collaborations',
          name: 'Student Collaborations',
          type: 'link',
          icon: 'collaborations-custom'
        },
        // {
        //     state: 'working_experience',
        //     name: 'Working Experience',
        //     type: 'link',
        //     icon: 'open-position-custom'
        // },
        // {
        //     state: 'upload_cv',
        //     name: 'Upload CV',
        //     type: 'link',
        //     icon: 'upload-cv-custom'
        // },
      ];
    } else {
      PROFILEACCADEMYMENUITEMS = [
        // {
        //   state: 'student_dashboard',
        //   name: 'Student Dashboard',
        //   type: 'link',
        //   icon: 'dashboard-custom'
        // },
        // {
        //   state: 'student_profile',
        //   name: 'Student Profile',
        //   type: 'link',
        //   icon: 'freelance-profile-custom'
        // },
        // {
        //   state: 'student_collaborations',
        //   name: 'Student Collaborations',
        //   type: 'link',
        //   icon: 'collaborations-custom'
        // },
        {
          state: 'working_experience',
          name: 'Working Experience',
          type: 'link',
          icon: 'open-position-custom'
        },
        {
          state: 'upload_cv',
          name: 'Upload CV',
          type: 'link',
          icon: 'upload-cv-custom'
        },
      ];
    }

    var ESPRINETADMINMENUITEMS: any = [];

    ESPRINETADMINMENUITEMS.push({
      state: 'company_list',
      name: 'Company',
      type: 'link',
      icon: 'company-profile-custom',
      priority: 1
    })

    ESPRINETADMINMENUITEMS.push({
      state: 'search_supplier',
      name: 'Search Company',
      type: 'link',
      icon: 'search-supplier',
      priority: 2
    })
    ESPRINETADMINMENUITEMS.push({
      state: 'find_services',
      name: 'Find Services',
      type: 'link',
      icon: 'search-services',
      priority: 4
    })
    ESPRINETADMINMENUITEMS.push({
      state: 'services_list',
      name: 'Company Services',
      type: 'link',
      icon: 'services_list',
      priority: 3
    })

    ESPRINETADMINMENUITEMS.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

    var CLIENTMENUITEMSESPRINET: any = [];

    CLIENTMENUITEMSESPRINET.push({
      state: 'find_services',
      name: 'Find Services',
      type: 'link',
      icon: 'search-services',
      priority: 1
    })

    CLIENTMENUITEMSESPRINET.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

    var SUPPLIERMENUITEMSESPRINETNOTAPPROVED: any = [];

    SUPPLIERMENUITEMSESPRINETNOTAPPROVED.push({
      state: 'company_profile_supplier',
      name: 'Company Profile',
      type: 'link',
      icon: 'company-profile-custom',
      priority: 1
    })

    SUPPLIERMENUITEMSESPRINETNOTAPPROVED.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

    var SUPPLIERMENUITEMSESPRINETAPPROVED: any = [];

    // SUPPLIERMENUITEMSESPRINETAPPROVED.push({
    //   state: 'dashboard_supplier',
    //   name: 'Dashboards',
    //   type: 'link',
    //   icon: 'dashboard-custom',
    //   priority: 1
    // })
    SUPPLIERMENUITEMSESPRINETAPPROVED.push({
      state: 'company_profile_supplier',
      name: 'Company Profile',
      type: 'link',
      icon: 'company-profile-custom',
      priority: 2
    })
    // TODO: chiedere cosa intendeva
    // SUPPLIERMENUITEMSESPRINETAPPROVED.push({
    //   state: '',
    //   name: 'RICHIESTE DI CONTATTO',
    //   type: 'link',
    //   icon: 'company-profile-custom',
    //   priority: 3
    // })

    SUPPLIERMENUITEMSESPRINETAPPROVED.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0))

    if (this.currentUser.role == "Administrator") {
      this.user_menus = MENUITEMS;
    } else if (this.currentUser.role == "Client") {
      if (window.location.hostname.toLowerCase().includes('esprinet')) {
        this.user_menus = CLIENTMENUITEMSESPRINET;
      } else {
        this.user_menus = CLIENTMENUITEMS;
      }
    } else if (this.currentUser.role == "Supplier") {
      if (window.location.hostname.toLowerCase().includes('esprinet')) {
        if (this.currentUser.id_doc_status == 1) {
          this.user_menus = SUPPLIERMENUITEMSESPRINETNOTAPPROVED;
        } else {
          this.user_menus = SUPPLIERMENUITEMSESPRINETAPPROVED;
        }
      } else {
        this.user_menus = SUPPLIERMENUITEMS;
      }

    } else if (this.currentUser.role == "Supplier Employees") {
      this.user_menus = EMPLOYEEMENUITEMS;
    } else if (this.currentUser.role == "Client Employees") {
      this.user_menus = CLIENTEMPLOYEEMENUITEMS;
    } else if (this.currentUser.role == "Supplier Management") {
      this.user_menus = SUPPLIERMANAGEMENMENUITEMS
    } else if (this.currentUser.role == "Accademy") {
      this.user_menus = ACCADEMYMENUITEMS
    } else if (this.currentUser.role == "Management Accademy") {
      this.user_menus = MANAGEMENTACCADEMYMENUITEMS
    } else if (this.currentUser.role == "Profile Accademy") {
      this.user_menus = PROFILEACCADEMYMENUITEMS
    } else if (this.currentUser.role == "Admin Management") {
      this.user_menus = ADMINMENUITEMS;
    } else if (this.currentUser.role == "Freelancer") {
      this.user_menus = FREELANCEMENUITEMS;
    } else if (this.currentUser.role == "Company Administrator" || this.currentUser.role == "Company Administrator Management") {
      this.user_menus = ESPRINETADMINMENUITEMS;
    }

    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    this.token = JSON.parse(sessionStorage.getItem("loginUserToken")!);
    if (this.currentUser.role == "Supplier" || this.currentUser.role == 'Supplier Management' || this.currentUser.role == 'Freelancer') {

      this.currentDocStatus = {
        id_doc_status: null,
        limit_date_documentation: null
      };

      var invocateGetPlanStatus = JSON.parse(sessionStorage.getItem('invocateGetPlanStatus')!);

      if ((invocateGetPlanStatus && invocateGetPlanStatus == 0 || !invocateGetPlanStatus)) {

        var dateInvocateGetPlanStatu = JSON.parse(sessionStorage.getItem('dateInvocateGetPlanStatu')!);
        var dateInvocateGetPlanStatuRefresh = JSON.parse(sessionStorage.getItem('dateInvocateGetPlanStatuRefresh')!);

        if (!dateInvocateGetPlanStatuRefresh || dateInvocateGetPlanStatu != dateInvocateGetPlanStatuRefresh) {
          this.fullComponent.getPlanStatus();
        }

        if (this.fullComponent.namePlanCurrent) {

          this.idPlanCurrent = this.fullComponent.idPlanCurrent;
          this.is_old_plan = this.fullComponent.is_old_plan;
          this.countPosition = this.fullComponent.countPosition;
          this.max_position = this.fullComponent.max_position;
          this.transactionFee = this.fullComponent.transactionFee;
          this.namePlanCurrent = this.fullComponent.namePlanCurrent;
          this.freePlan = this.fullComponent.freePlan

          this.planStatus = this.fullComponent.planStatus;

          this.freePlan = this.fullComponent.freePlan;

          this.limitations = this.fullComponent.limitations;
          sessionStorage.removeItem('userLimitation');
          sessionStorage.setItem('userLimitation', JSON.stringify(this.limitations))
          this.limitationsArray = JSON.parse(sessionStorage.getItem('userLimitation')!)
          //console.log(this.limitationsArray);
          this.limitationsArray.forEach((element: any) => {
            switch (element.state_function) {
              case "manage_users_supplier":
                this.manageUserBlock = element
                break;
              case "SPC":
                this.discoverCompatibleProfileBlock = element
                break;
              case "tEm":
                this.timeMaterialBlock = element
                break;
              case "PFP":
                this.fixedPriceBlock = element
                break;
              case "VP":
                this.showEstimateBlock = element
                break;
              case "accademy_profiles":
                this.hireBlock = element
                break;
              case "chat":
                this.chatBlock = element
                break;
              case "request_available":
                this.requestAvailableBlock = element
                break;
              case "download_cv":
                this.downloadCVBlock = element
                break;
              case "information_blocked":
                this.informationBlock = element
                break;
              case "emails_contact":
                this.emailsContactBlock = element
                break;
              case "download_company":
                this.downloadCompanyReportBlock = element
                break;
              case "search_partner":
                this.searchPartnerBlock = element
                this.existInLimitationSearch = true;
                break;
              default:
                break;
            }
          });

          if (this.existInLimitationSearch == false) {
            this.searchPartnerBlock = { blocked: true, action: 1 };
            this.downloadCompanyReportBlock = { blocked: true, action: 1 };
          }

        }
      } else {

        const format1 = "YYYY-MM-DD HH:mm:ss"
        var date2 = new Date();
        var date_for_check = moment(date2).format(format1);
        sessionStorage.setItem('dateInvocateGetPlanStatusRefresh', JSON.stringify(date_for_check));

      }

      this.dateNow = new Date();
      if (this.fullComponent.currentDocStatus && this.fullComponent.currentDocStatus.id_doc_status == 4) {
        if (this.fullComponent.currentDocStatus.limit_date_documentation) {
          this.limitDateDoc = new Date(this.fullComponent.currentDocStatus.limit_date_documentation);
        }
        if (this.dateNow > this.limitDateDoc) {
          this.expiredDate = true;
        }
      }
      this.currentDocStatus = this.fullComponent.currentDocStatus;
      this.planStatus = 0;
      sessionStorage.setItem('freePlan', this.fullComponent.freePlan.toString())

      this.loadData()

    }
    if (this.currentUser.role == "Accademy" || this.currentUser.role == 'Management Accademy') {
      var invocateGetPlanStatus = JSON.parse(sessionStorage.getItem('invocateGetPlanStatus')!);

      if ((invocateGetPlanStatus && invocateGetPlanStatus == 0 || !invocateGetPlanStatus)) {

        var dateInvocateGetPlanStatu = JSON.parse(sessionStorage.getItem('dateInvocateGetPlanStatu')!);
        var dateInvocateGetPlanStatuRefresh = JSON.parse(sessionStorage.getItem('dateInvocateGetPlanStatuRefresh')!);

        if (!dateInvocateGetPlanStatuRefresh || dateInvocateGetPlanStatu != dateInvocateGetPlanStatuRefresh) {
          this.fullComponent.getPlanStatus();
        }

        if (this.fullComponent.namePlanCurrent) {

          this.idPlanCurrent = this.fullComponent.idPlanCurrent;
          this.is_old_plan = this.fullComponent.is_old_plan;
          this.countPosition = this.fullComponent.countPosition;
          this.max_position = this.fullComponent.max_position;
          this.transactionFee = this.fullComponent.transactionFee;
          this.namePlanCurrent = this.fullComponent.namePlanCurrent;
          this.freePlan = this.fullComponent.freePlan

          this.planStatus = this.fullComponent.planStatus;

          this.freePlan = this.fullComponent.freePlan;

          this.limitations = this.fullComponent.limitations;
          sessionStorage.removeItem('userLimitation');
          sessionStorage.setItem('userLimitation', JSON.stringify(this.limitations))
          this.limitationsArray = JSON.parse(sessionStorage.getItem('userLimitation')!)
          //console.log(this.limitationsArray);
          this.limitationsArray.forEach((element: any) => {
            switch (element.state_function) {
              case "manage_users_supplier":
                this.manageUserBlock = element
                break;
              case "SPC":
                this.discoverCompatibleProfileBlock = element
                break;
              case "tEm":
                this.timeMaterialBlock = element
                break;
              case "PFP":
                this.fixedPriceBlock = element
                break;
              case "VP":
                this.showEstimateBlock = element
                break;
              case "accademy_profiles":
                this.hireBlock = element
                break;
              case "chat":
                this.chatBlock = element
                break;
              case "request_available":
                this.requestAvailableBlock = element
                break;
              case "download_cv":
                this.downloadCVBlock = element
                break;
              case "information_blocked":
                this.informationBlock = element
                break;
              case "emails_contact":
                this.emailsContactBlock = element
                break;
              case "download_company":
                this.downloadCompanyReportBlock = element
                break;
              case "search_partner":
                this.searchPartnerBlock = element
                this.existInLimitationSearch = true;
                break;
              default:
                break;
            }
          });

          if (this.existInLimitationSearch == false) {
            this.searchPartnerBlock = { blocked: true, action: 1 };
            this.downloadCompanyReportBlock = { blocked: true, action: 1 };
          }

        }
      } else {

        const format1 = "YYYY-MM-DD HH:mm:ss"
        var date2 = new Date();
        var date_for_check = moment(date2).format(format1);
        sessionStorage.setItem('dateInvocateGetPlanStatusRefresh', JSON.stringify(date_for_check));

      }

      this.dateNow = new Date();
      if (this.fullComponent.currentDocStatus && this.fullComponent.currentDocStatus.id_doc_status == 4) {
        if (this.fullComponent.currentDocStatus.limit_date_documentation) {
          this.limitDateDoc = new Date(this.fullComponent.currentDocStatus.limit_date_documentation);
        }
        if (this.dateNow > this.limitDateDoc) {
          this.expiredDate = true;
        }
      }
      this.currentDocStatus = this.fullComponent.currentDocStatus;
      this.planStatus = 0;
      sessionStorage.setItem('freePlan', this.fullComponent.freePlan.toString())

      this.loadData()

    }
    if (this.currentUser.role == "Client" || this.currentUser.role == 'Client Employees') {
      this.getCounterListInvite()
    }
    this.getCounterServices()

    setTimeout(() => {
      this.textLogo = document.getElementsByClassName("text-logo")[0];
    }, 1);
  }

  createCompanyEsprinet(action: string, obj: any) {
    // obj.action = action;
    // obj.fromEsprinet = true;
    this.router.navigate(['/company_create'])
    // const dialogRef = this.dialog.open(CompanyDialogContent, {
    //   data: obj
    // });

    // dialogRef.afterClosed().subscribe(result => {
    //   if (result.event == "login") {
    //     this.router.navigate(['/']);
    //   } else if (result.event == "error") {
    //     this.manageError();
    //   } else if (result.event == "Update" || result.event == "Add" || result.event == "Delete") {
    //     window.location.reload();
    //   }
    // });
  }

  ngDoCheck(): void {
    let menuSaved = JSON.parse(sessionStorage.getItem("menuVoice")!);
    if (menuSaved == null) {
      if (this.currentUser.role == "Supplier" || this.currentUser.role == 'Freelancer' || this.currentUser.role == 'Client' || this.currentUser.role == 'Supplier Management' || this.currentUser.role == 'Client Employees'
        || this.currentUser.role == 'Accademy' || this.currentUser.role == 'Management Accademy') {
        this.setBg('Dashboards', false);
        menuSaved = 'Dashboards';
      } else if (this.currentUser.role == "Supplier Employees") {
        this.setBg('Working Experience', false);
        menuSaved = 'Working Experience';
      } else if (this.currentUser.role == "Administrator") {
        this.setBg('Company', false);
        menuSaved = 'Company';
      } else if (this.currentUser.role == "Freelancer") {
        this.setBg('Freelance Profile', false);
        menuSaved = 'Freelance Profile';
      }
    }
    this.activeMenu = menuSaved;
    var counter = sessionStorage.getItem('refresh-collab');
    if (counter == "true") {
      if (this.currentUser.role == "Supplier" || this.currentUser.role == 'Freelancer' || this.currentUser.role == 'Supplier Management' || this.currentUser.role == 'Accademy' || this.currentUser.role == 'Management Accademy' || this.currentUser.role == 'Freelancer') {
        this.loadData();
      } else if (this.currentUser.role == 'Client' || this.currentUser.role == 'Client Employees') {
        this.getCounterListInvite();
      }
      this.getCounterServices()
      sessionStorage.removeItem('refresh-collab');
    }
    if (sessionStorage.getItem('verifyBtn') == 'true') {

      var invocateGetNumberCredits = JSON.parse(sessionStorage.getItem('invocateGetNumberCredits')!);

      if ((invocateGetNumberCredits && invocateGetNumberCredits == 0 || !invocateGetNumberCredits)) {

        var dateInvocateGetNumberCredits = JSON.parse(sessionStorage.getItem('dateInvocateGetNumberCredits')!);
        var dateInvocateGetNumberCreditsRefresh = JSON.parse(sessionStorage.getItem('dateInvocateGetNumberCreditsRefresh')!);

        if (!dateInvocateGetNumberCreditsRefresh || dateInvocateGetNumberCredits != dateInvocateGetNumberCreditsRefresh) {
          this.fullComponent.getNumberCredits();
        }

        if (this.fullComponent.namePlanCurrent) {

          this.state = this.fullComponent.state;
          this.countProjects = this.fullComponent.countProjects;
          this.countPosition = this.fullComponent.countPosition;
          this.idPlanCurrent = this.fullComponent.idPlanCurrent;
          this.max_project = this.fullComponent.max_project;
          this.max_position = this.fullComponent.max_position;

          sessionStorage.removeItem('verifyBtn');

        }
      } else {

        const format1 = "YYYY-MM-DD HH:mm:ss"
        var date2 = new Date();
        var date_for_check = moment(date2).format(format1);
        sessionStorage.setItem('dateInvocateGetNumberCreditsRefresh', JSON.stringify(date_for_check));

      }

    }

  }

  ngAfterViewChecked(): void {

    this.state = this.fullComponent.state;
    this.countProjects = this.fullComponent.countProjects;
    this.countPosition = this.fullComponent.countPosition;
    this.idPlanCurrent = this.fullComponent.idPlanCurrent;
    this.max_project = this.fullComponent.max_project;
    this.max_position = this.fullComponent.max_position;
    this.planStatus = this.fullComponent.planStatus;
    this.idPlanCurrent = this.fullComponent.idPlanCurrent;
    this.is_old_plan = this.fullComponent.is_old_plan;
    this.countPosition = this.fullComponent.countPosition;
    this.max_position = this.fullComponent.max_position;
    this.transactionFee = this.fullComponent.transactionFee;
    this.namePlanCurrent = this.fullComponent.namePlanCurrent;
    this.freePlan = this.fullComponent.freePlan;
    this.currentDocStatus = this.fullComponent.currentDocStatus;
    this.getPlanStatusRes = this.fullComponent.getPlanStatusRes;
    this.logo = this.appComponent.logoPlatform
    this.cdRef.detectChanges();

  }

  ngOnInit(): void {
    if (this.currentUser.role == "Client" || this.currentUser.role == 'Client Employees') {
      this.state = this.fullComponent.state;
      this.countProjects = this.fullComponent.countProjects;
      this.countPosition = this.fullComponent.countPosition;
      this.idPlanCurrent = this.fullComponent.idPlanCurrent;
      this.max_project = this.fullComponent.max_project;
      this.max_position = this.fullComponent.max_position;
    }

    if (window.location.href.includes('company_list')) {
      sessionStorage.removeItem('menuVoice');
      sessionStorage.setItem('menuVoice', JSON.stringify('Company'));
      this.returned = 'company_list'
    } else if (window.location.href.includes('company_review')) {
      sessionStorage.removeItem('menuVoice');
      sessionStorage.setItem('menuVoice', JSON.stringify('Company'));
      this.returned = 'company_review'
    } else if (window.location.href.includes('services_list')) {
      sessionStorage.removeItem('menuVoice');
      sessionStorage.setItem('menuVoice', JSON.stringify('Company Services'));
      this.returned = 'services_list'
    } else if (window.location.href.includes('services_review')) {
      sessionStorage.removeItem('menuVoice');
      sessionStorage.setItem('menuVoice', JSON.stringify('Company Services'));
      this.returned = 'services_review'
    } else {
      this.returned = ''
    }

    this.router.events.subscribe((val: any) => {

      if (window.location.href.includes('company_list')) {
        sessionStorage.removeItem('menuVoice');
        sessionStorage.setItem('menuVoice', JSON.stringify('Company'));
        this.returned = 'company_list'
      } else if (window.location.href.includes('company_review')) {
        sessionStorage.removeItem('menuVoice');
        sessionStorage.setItem('menuVoice', JSON.stringify('Company'));
        this.returned = 'company_review'
      } else if (window.location.href.includes('services_list')) {
        sessionStorage.removeItem('menuVoice');
        sessionStorage.setItem('menuVoice', JSON.stringify('Company Services'));
        this.returned = 'services_list'
      } else if (window.location.href.includes('services_review')) {
        sessionStorage.removeItem('menuVoice');
        sessionStorage.setItem('menuVoice', JSON.stringify('Company Services'));
        this.returned = 'services_review'
      } else {
        this.returned = ''
      }

    })

    this.getCountersEsprinet();
    let that = this
    setInterval(function () {
      // Invoke function every 10 minutes
      that.getCountersEsprinet();
    }, 600000);
  }

  setBg(menuitem: any, boolean: boolean) {
    //console.log(this.activeMenu);

    sessionStorage.setItem('menuVoice', JSON.stringify(menuitem));
    this.activeMenu = menuitem;
    removeBackRequest()
    localStorage.removeItem('currentSearchQuery')!
    if (this.responsiveWidth <= 992) {
      this.onSelectedMenuItem.emit(boolean);
    }
  }
  openSubMenu() {
    if (this.currentUser.role == 'Supplier' || this.currentUser.role == 'Supplier Management' || this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management' || this.currentUser.role == 'Freelancer') {

      let positionListSubMenu = <HTMLElement>document.getElementsByClassName('sub-menu')[0];
      if (positionListSubMenu.classList.contains('closed')) {
        positionListSubMenu.classList.replace('closed', 'open')
        this.subMenuOpen = true;
      } else if (positionListSubMenu.classList.contains('open')) {
        positionListSubMenu.classList.replace('open', 'closed')
        this.subMenuOpen = false;
      }
    }
  }
  closeSubMenu() {
    if (this.currentUser.role == 'Supplier' || this.currentUser.role == 'Supplier Management' || this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management' || this.currentUser.role == 'Freelancer') {
      let positionListSubMenu = <HTMLElement>document.getElementsByClassName('sub-menu')[0];
      if (positionListSubMenu.classList.contains('open')) {
        positionListSubMenu.classList.replace('open', 'closed')
        this.subMenuOpen = false;
      }
    }
  }
  isInStep(type: any, menuName: any) {
    //console.log(menuName);
    if (this.currentUser.role == "Company Administrator" || this.currentUser.role == "Company Administrator Management") {
      setTimeout(() => {
        this.returned = '';
        switch (type) {
          case 0:
            if (window.location.href.includes('company_list')) {
              this.returned = 'company_list'
            }
            break;
          case 1:
            if (window.location.href.includes('company_review')) {
              this.returned = 'company_review'
            }
            break;
          case 2:
            if (window.location.href.includes('services_list')) {
              this.returned = 'services_list'
            }
            break;
          case 3:
            if (window.location.href.includes('services_review')) {
              this.returned = 'services_review'
            }
            break;
          default:
            break;
        }
      }, 200);
    }

  }

  setClickedRow(i: number, j: number) {
    this.parentIndex = i;
    this.childIndex = j;
  }
  subclickEvent() {
    this.status = true;
  }
  getCountersEsprinet() {
    if (this.currentUser.role == "Company Administrator" || this.currentUser.role == "Company Administrator Management") {
      this._service.getCompanyCounter().then(res => {
        if (res.success) {
          this.companyCounter = res.data
        }
        this._service.getServicesCounter().then(res => {
          if (res.success) {
            this.servicesCounter = res.data
          }

        }).catch(error => {
          if (error.status == 401) {
            this.fullComponent.refreshToken(1);

          } else {
            this.manageError();

          }

        });
      }).catch(error => {
        if (error.status == 401) {
          this.fullComponent.refreshToken(1);

        } else {
          this.manageError();

        }

      });
    }
  }
  scrollToTop() {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0
    });
  }

  loadData() {
    this._service.getCounterListRequest().then(res => {
      this.counterListRequest = res.data;
    }).catch(error => {
      if (error.status == 401) {
        this.fullComponent.refreshToken(1);

      } else {
        this.manageError();
      }

    });
  }

  getCounterListInvite() {
    this._service.getCounterListInvite().then(res => {
      this.counterListInvite = res.data;
    }).catch(error => {
      if (error.status == 401) {
        this.fullComponent.refreshToken(1);

      } else {
        this.manageError();
      }

    });
  }

  getCounterServices() {
    if (this.currentUser.role == "Company Administrator" || this.currentUser.role == "Company Administrator Management") {

      this._service.getCounterServiceApproved().then(res => {
        this.counterServices = res.data;
      }).catch(error => {
        if (error.status == 401) {
          this.fullComponent.refreshToken(1);

        } else {
          this.manageError();
        }

      });
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  manageError() {
    if (this.currentUser.language == "it") {
      var errorMessage = this.translate.translations.it.ErrorServer;
    } else {
      var errorMessage = this.translate.translations.en.ErrorServer;
    }
    this._snackBar.open(errorMessage, '', {
      duration: 5000,
      panelClass: ['danger-snackbar'],
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,

    });
  }

  changeCreate(element: any) {
    if (element == 'Need') {
      sessionStorage.removeItem('menuVoice');
      sessionStorage.setItem('menuVoice', JSON.stringify('Need'));
      sessionStorage.setItem('sourceAddProject', JSON.stringify('Sidebar'));
      this.router.navigate(['/need/create']);
    } else if (element == 'Profile') {
      sessionStorage.removeItem('menuVoice');
      sessionStorage.setItem('menuVoice', JSON.stringify('Employees'));
      sessionStorage.setItem('sourceAddProfile', JSON.stringify('Sidebar'));
      this.router.navigate(['employees/add-employee']);
    }
  }

}
