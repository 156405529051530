import { PlansComponent } from './../../plans/plans.component';

import { SavedProfilesComponent } from './../../saved-profiles/saved-profiles.component';
import { AddEmployeeComponent } from './../../employees/add-employee/add-employee.component';
import { AddProfileComponent } from './../../position-list/add-profile/add-profile.component';
import { AddProjectComponent } from './../../need/add-project/add-project.component';
import { PositionListComponent } from './../../position-list/position-list.component';
import { ManageUsersSupplierComponent } from './../../manage-users-supplier/manage-users-supplier.component';
import { ManageUsersComponent } from './../../manage-users/manage-users.component';

import { CustomerProfileComponent } from './../../customer-profile/customer-profile.component';
import { CollaborationsRequestComponent } from './../../collaborations-request/collaborations-request.component';
import { EmployeesComponent } from './../../employees/employees.component';
import { CompanyProfileComponent } from './../../company-profile/company-profile.component';

import { CollaborationsInviteComponent } from './../../collaborations-invite/collaborations-invite.component';
import { MatchesComponent } from './../../need/matches/matches.component';
import { ProfileAvailableComponent } from './../../profile-available/profile-available.component';
import { NeedComponent } from './../../need/need.component';

import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationEnd, ActivatedRoute, Data } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit

} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { MenuItems } from '../../shared/menu-items/menu-items';
import { filter, map, mergeMap } from 'rxjs/operators';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Title } from '@angular/platform-browser';
import { DigitizeCvComponent } from 'src/app/digitize-cv/digitize-cv.component';

import { MyPlansComponent } from 'src/app/my-plans/my-plans.component';
import { MyProfileComponent } from 'src/app/my-profile/my-profile.component';

import { VerticalSidebarService } from './vertical-sidebar/vertical-sidebar.service';

import { NotificationsPageComponent } from 'src/app/notifications-page/notifications-page.component';

import { CompaniesProposeComponent } from 'src/app/need/companies-propose/companies-propose.component';
import { MatDialog } from '@angular/material/dialog';
import { SessionExpiredModalComponent } from 'src/app/session-expired-modal/session-expired-modal.component';
import { CompleteDetailsComponent } from 'src/app/complete-details/complete-details.component';

import * as mixpanel from 'mixpanel-browser';
import { ExternalFeedbackModalComponent } from 'src/app/external-feedback-modal/external-feedback-modal.component';
import { EServicesListComponent } from './../../e-services-list/e-services-list.component';
import { EServicesReviewComponent } from 'src/app/e-services-review/e-services-review.component';
import { ECompanyListComponent } from './../../e-company-list/e-company-list.component';
import { ECompanyReviewComponent } from './../../e-company-review/e-company-review.component';

import { CompanyProfileSupplierComponent } from 'src/app/company-profile-supplier/company-profile-supplier.component';
import { EFindServicesComponent } from 'src/app/e-find-services/e-find-services.component';
import { ERegistrationStepperComponent } from 'src/app/e-registration-stepper/e-registration-stepper.component';
import { SearchSupplierComponent } from 'src/app/search-supplier/search-supplier.component';

import { createPopup, createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";
import "@typeform/embed/build/css/widget.css"; //import widget css
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Helper } from 'src/helper/helper';
import * as moment from 'moment';

import { EmailNotificationDetailComponent } from 'src/app/email-notification/email-notification-detail/email-notification-detail.component';
import { VerticalAppHeaderComponent } from './vertical-header/vertical-header.component';
import { AppComponent } from 'src/app/app.component';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['./full-component.scss']
})

export class FullComponent implements OnDestroy, OnInit {

  pageInfo: Data = Object.create(null);
  titlePage: any;
  titlePageKey: any;
  mobileQuery: MediaQueryList;
  dir = 'ltr';
  green = false;
  blue = false;
  dark = false;
  minisidebar = false;
  boxed = false;
  danger = false;
  showHide = false;
  horizontal = false;
  url = '';
  sidebarOpened = false;
  status = false;
  redirect = "";
  currentUser: any;
  public showSearch = false;
  assumedUser: any;
  versionProduct: any;
  token: any;
  countModaleExpired = 0;
  limitations: any = [];
  limitationsArray: any = [];
  manageUserBlock = { blocked: false, action: 1 };
  discoverCompatibleProfileBlock = { blocked: false, action: 1, create: false, sourcePage: '' };
  timeMaterialBlock = { blocked: false, action: 1, create: false, sourcePage: '' };
  fixedPriceBlock = { blocked: false, action: 1, create: false, sourcePage: '' };
  showEstimateBlock = { blocked: false, action: 1, create: false, sourcePage: '' };
  hireBlock = { blocked: false, action: 1 };
  chatBlock = { blocked: false, action: 1, sourcePage: '' };
  globalState = { idPlanCurrent: 0, isOldPlan: false, countPosition: 0, max_position: 0, transactionFee: null };
  requestAvailableBlock = { blocked: false, action: 1 };
  emailsContactBlock = { blocked: false, action: 1 };
  shareProfileBlock = { blocked: false, action: 1 };
  shareProjectBlock = { blocked: false, action: 1 };
  informationBlock = { blocked: false, action: 1 };
  downloadCVBlock = { blocked: false, action: 1 };
  downloadCompanyReportBlock = { blocked: false, action: 1 };
  searchPartnerBlock = { blocked: false, action: 1 };
  activeSegmentDev = false;
  counterCallRefreshToken = 0;
  state: any;
  existInLimitationSearch = false;
  seeBannerFeedback = true;
  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;
  newMessagesLed: any;
  codificArrayR: any = [];
  codificArrayE: any = [];
  codificArrayKnow: any = [];
  codificArrayS: any = [];
  codificArrayTd: any = [];
  codificArrayPd: any = [];
  codificArrayNc: any = [];
  showTypeform: any;

  routingList: any;
  cbInstance: any;
  date_for_check: any
  errMsg: any;
  contRequest = 0;
  freePlan = false;
  loadingSpin = false;
  logo: any = 'assets/images/new-card-icons/loading-logo-proc.svg'

  creditAvailable: any;
  credits: any;
  creditsUsed: any;
  namePlanCurrent: any;
  idPlanCurrent: any;
  price: any;
  limit: any;
  is_old_plan: any;

  countProjects: any;
  countPosition: any;
  max_project: any;
  max_position: any;
  informationBlocked: any;
  assumePlan: any;
  n_max_users: any;
  currentPlanData: any;

  planStatus: any;
  transactionFee: any;

  currentDocStatus: any;
  getPlanStatusRes: any;
  checkNumberDay = 0;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private translate: TranslateService,
    private _service: VerticalSidebarService,
    public dialog: MatDialog,
    private http: HttpClient,
    private _helper: Helper,
    private ref: ChangeDetectorRef,
    public appComponent: AppComponent,
    // private verticalHeader: VerticalAppHeaderComponent
  ) {

    if (window.location.host == 'marketplace.timeflow.it') {
      this.cbInstance = (<any>window)['Chargebee'].init({
        site: "timeflow"
      });
    } else {
      this.cbInstance = (<any>window)['Chargebee'].init({
        site: "timeflow-test"
      });
    }

    this.mobileQuery = media.matchMedia('(min-width: 1023px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
    this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);

    this.logo = appComponent.logoSpinner
    console.log(this.logo);

    // if(this.versionProduct == 2){
    //   this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    // } else if(this.versionProduct == 1){
    //   if (window.location.host == "procurement.timeflow.it") {
    //     this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else if (window.location.host == "whitelabel.daytrend.it" ) {
    //     this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else if (window.location.host == "procurement.daytrend.it" ) {
    //     this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else if (window.location.host == "dev.mkt.daytrend.it" ) {
    //     this.logo = 'assets/images/new-card-icons/loading-logo.svg';
    //   } else {
    //     this.logo = 'assets/images/new-card-icons/loading-logo-proc.svg';
    //   }
    // }

    this.showTypeform = JSON.parse(sessionStorage.getItem("showTypeform")!);
    if (this.currentUser.role == "Administrator" || this.currentUser.role == "Supplier Employees" || this.currentUser.role == "Profile Accademy") {
      this.redirect = '/my_profile';
    } else if (this.currentUser.role == "Supplier" || this.currentUser.role == 'Supplier Management') {
      this.redirect = '/dashboard_supplier';
    } else if (this.currentUser.role == "Client") {
      this.redirect = '/dashboard_client';
    } else if (this.currentUser.role == "Accademy" || this.currentUser.role == 'Management Accademy') {
      this.redirect = '/dashboard_academy';
    } else if (this.currentUser.role == "Freelancer") {
      this.redirect = '/freelance_profile';
    }
    this.setTitle();

    this.getCodificValues()

    if ((this.currentUser.role == "Client" || this.currentUser.role == "Supplier"
      || this.currentUser.role == "Client Employees" || this.currentUser.role == "Supplier Management" || this.currentUser.role == "Accademy"
      || this.currentUser.role == "Management Accademy" || this.currentUser.role == "Freelance") && this.versionProduct == 2 && this.showTypeform == 1 && this.currentUser.send_feedback == 0 && this.currentUser.company_profile_profiled == 1 && window.location.host == 'marketplace.timeflow.it') {

      setTimeout(() => {

        var id = '';
        if (this.currentUser.language == 'it') {
          id = "fJyEfOxH";
        } else {
          id = "SSFxwYPM";
        }

        createPopup(id, {
          size: 100, fullScreen: true,
          hidden: {
            role: (this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
              this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
                'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                  'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance'), email: this.currentUser.email
          },
          autoClose: 2000,
          onSubmit: () => {
            sessionStorage.setItem('showTypeform', JSON.stringify(0));
            this._service.setSendFeedback(1).then(res => {
              this.currentUser.send_feedback = 1;
              sessionStorage.setItem('loginUser', JSON.stringify(this.currentUser));

            }).catch(error => {
              if (error.status == 401) {
                this.refreshToken(1);

              } else {

              }

            });

          },
          onClose: () => {
            sessionStorage.setItem('showTypeform', JSON.stringify(0));
          },
        }).open();

      }, 3000);
    }

  }
  openTypeform(type: any) {

    var id = '';
    if (this.currentUser.language == 'it') {
      id = "fJyEfOxH";
    } else {
      id = "SSFxwYPM";
    }

    createPopup(id, {
      size: 100, fullScreen: true,
      hidden: {
        role: (this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
          this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
            'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
              'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance'), email: this.currentUser.email
      },
      autoClose: 2000,
      onSubmit: () => {
        sessionStorage.setItem('showTypeform', JSON.stringify(0));
        this._service.setSendFeedback(1).then(res => {
          this.currentUser.send_feedback = 1;
          sessionStorage.setItem('loginUser', JSON.stringify(this.currentUser));

        }).catch(error => {
          if (error.status == 401) {
            this.refreshToken(1);

          } else {

          }

        });
      },
      onClose: () => {
        sessionStorage.setItem('showTypeform', JSON.stringify(0));
      },
    }).open();
  }

  checkUrlForBundle() {
    let url = window.location.hostname.toLowerCase()

    if (url.includes('add-employee') || url.includes('candidacy') || url.includes('add-profile')) {
      return false
    } else {

      return true
    }
  }
  getNumberCredits() {

    sessionStorage.setItem('invocateGetNumberCredits', JSON.stringify(1));

    if (this.currentUser.purchase_plan == 1) {

      this.openCheckout();
    }

    this.namePlanCurrent = null;

    this._service.getNumberCredits().then(res => {

      sessionStorage.setItem('invocateGetNumberCredits', JSON.stringify(0));
      const format1 = "YYYY-MM-DD HH:mm:ss"
      var date2 = new Date();
      var date_for_check = moment(date2).format(format1);
      sessionStorage.setItem('dateInvocateGetNumberCredits', JSON.stringify(date_for_check));

      this.state = res.data.state;
      this.globalState = res.data;
      this.creditAvailable = res.data.creditAvailable;
      this.credits = res.data.credits;
      this.creditsUsed = res.data.creditsUsed;
      this.namePlanCurrent = res.data.namePlanCurrent;
      this.idPlanCurrent = res.data.idPlanCurrent;
      this.price = res.data.price;
      this.limit = res.data.limit;
      this.is_old_plan = res.data.is_old_plan;
      this.currentPlanData = res.data;

      this.countProjects = res.data.countProjects;
      this.countPosition = res.data.countPosition;
      this.max_project = res.data.max_project;
      this.max_position = res.data.max_position;
      this.informationBlocked = res.data.informationBlocked;
      this.assumePlan = res.data.assumePlan;
      this.n_max_users = res.data.n_max_users

      ////console.log(this.globalState);
      this.limitations = res.data.limitations;
      sessionStorage.removeItem('userLimitation');
      sessionStorage.setItem('userLimitation', JSON.stringify(this.limitations))
      this.limitationsArray = JSON.parse(sessionStorage.getItem('userLimitation')!);
      this.limitationsArray.forEach((element: any) => {
        switch (element.state_function) {
          case "manage_users":
            this.manageUserBlock = element
            break;
          case "SPC":
            this.discoverCompatibleProfileBlock = element
            break;
          case "tEm":
            this.timeMaterialBlock = element
            break;
          case "PFP":
            this.fixedPriceBlock = element
            break;
          case "VP":
            this.showEstimateBlock = element
            break;
          case "accademy_profiles":
            this.hireBlock = element
            break;
          case "chat":
            this.chatBlock = element
            break;
          case "request_available":
            this.requestAvailableBlock = element
            break;
          case "download_cv":
            this.downloadCVBlock = element
            break;
          case "information_blocked":
            this.informationBlock = element
            break;
          case "share_profile_information":
            this.shareProfileBlock = element
            break;
          case "share_position_information":
            this.shareProjectBlock = element
            break;
          case "emails_contact":
            this.emailsContactBlock = element
            break;
          case "download_company":
            this.downloadCompanyReportBlock = element
            break;
          case "search_partner":
            this.searchPartnerBlock = element
            this.existInLimitationSearch = true;
            break;
          default:
            break;
        }
      });

      if (this.existInLimitationSearch == false) {
        this.searchPartnerBlock = { blocked: true, action: 1 };
        this.downloadCompanyReportBlock = { blocked: true, action: 1 };
      }

      this._service.getDocumentationStatus().then(res => {
        this.currentDocStatus = res.data;
        if (this.currentDocStatus && this.currentDocStatus.id_doc_status == 3 && this.currentUser.role != "Supplier Employees" && this.currentUser.role != "Profile Accademy") {
          this.router.navigate(['/authentication/login']);
        }
      }).catch(error => {
        if (error.status == 401) {
          this.refreshToken(1);

        } else {

        }

      });

    }).catch(error => {
      if (error.status == 401) {
        this.refreshToken(1);

      } else {

      }

    });
  }

  getCodificValues() {
    this._service.getCodificValues().then((res: any) => {
      //console.log(res.data);
      this.codificArrayE = res.data.number_employees
      this.codificArrayR = res.data.revenue
      this.codificArrayS = res.data.seniority
      this.codificArrayTd = res.data.total_deal
      this.codificArrayPd = res.data.project_duration
      this.codificArrayNc = res.data.number_candidacy
      this.codificArrayKnow = res.data.how_do_you_know

    })
  }

  ngOnInit() {
    this.token = JSON.parse(sessionStorage.getItem("loginUserToken")!);
    let banner = sessionStorage.getItem("bannerFeedback")!;

    if (banner && banner == 'setFalse') {
      this.seeBannerFeedback = false;
    } else {
      this.seeBannerFeedback = true;
    }
    if (this.currentUser.role == "Client" || this.currentUser.role == 'Client Employees') {
      this.getNumberCredits()
    } else if (this.currentUser.role == "Supplier" || this.currentUser.role == 'Supplier Employees') {
      this.getPlanStatus()
    }
    if (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') {
      this.searchPartnerBlock = { blocked: false, action: 1 };
    }

    this.listFunction();

    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);

  }

  openCheckout() {

    this.cbInstance.openCheckout({
      hostedPage: () => {
        //console.log(this.local_data);
        let addons = '';

        let obj = {
          plan_id: this.currentUser.id_plan_purchase,
          plan_charge: this.currentUser.name_plan_purchase,
          addon: addons
        }

        //console.log(obj);

        return this.http.post(this._helper.getBaseUrl() + 'chargebee/openCheckoutSubscription', this.getFormUrlEncoded(obj), { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': `Bearer ${this._helper.getUserConfigData().access_token}` }) }).toPromise();
      },

      loaded: () => {
        //console.log("checkout opened");
        const format1 = "YYYY-MM-DD HH:mm:ss"
        var date2 = new Date();
        this.date_for_check = moment(date2).format(format1);
      },
      error: (error: any) => {
        this.ref.markForCheck();
        this.errMsg = true;

        if (error.status == 401) {
          this._service.getRefreshToken(this.token.access_token).then(res => {
            sessionStorage.removeItem('loginUserToken');
            sessionStorage.setItem('loginUserToken', JSON.stringify(res.data.token));
            sessionStorage.removeItem('loginUser');
            sessionStorage.setItem('loginUser', JSON.stringify(res.data.user));
            this.openCheckout();
          }).catch(error => {

            if (error.status == 401) {
              this.router.navigate(['/']);
            } else {
            }
          });

        } else {
        }

      },
      close: () => {
        ;
        this.ref.detectChanges();

      },
      success: (hostedPageId: any) => {
        this.ref.detectChanges();

        var loginUserNew = JSON.parse(sessionStorage.getItem("loginUser")!);
        loginUserNew.purchase_plan = 0;
        sessionStorage.setItem('loginUser', JSON.stringify(loginUserNew));
        this.refreshSubscription(this.currentUser.id_plan_purchase, this.date_for_check, this.currentUser.title_plan);

      },
      step: (value: any) => {
        //console.log(value);
      }
    });
  }

  refreshSubscription(plan_id: any, date_for_check: any, title: any) {
    setTimeout(() => {
      this.getCheckSubscriptionIsUpdated(plan_id, date_for_check, title)
    }, 1000);
  }

  getCheckSubscriptionIsUpdated(plan_id: any, date_for_check: any, title: any) {
    this.loadingSpin = true;
    this._service.getCheckSubscriptionIsUpdated(plan_id, date_for_check).then(res => {
      if (res.data != null && res.data != 0) {

        var objSegment = {};

        objSegment = {
          action: ((this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? '[STAFF] Acquisti in app: Nuovo abbonamento' : this.assumedUser == 1 ? '[STAFF] Acquisti in app: Nuovo abbonamento' : 'Acquisti in app: Nuovo abbonamento'),
          id_current_plan: plan_id,
          plan_title: title,
          note: title
        };

        if (this.activeSegmentDev == true) {

          this.trackSegment(objSegment);
        }

        this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
        this.assumedUser = JSON.parse(sessionStorage.getItem("assumedUser")!);

        this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);

        if (this.versionProduct == 2) {
          /* IA - SEGMENT */
          if (window.location.host == 'marketplace.timeflow.it') {
            this.trackSegment(objSegment);

          }
        } else {

          if (window.location.host == 'localhost:4200') {

          } else {

            if (window.location.host == "procurement.timeflow.it") {
              this.trackSegment(objSegment);

            } else if (window.location.host == "whitelabel.daytrend.it") {

            } else if (window.location.host == "procurement.daytrend.it") {

            } else if (window.location.host == "dev.mkt.daytrend.it") {

            } else {
              if (!(window.location.host.includes('daytrend'))) {

                this.trackSegment(objSegment);

              }
            }
          }
        }

        var back = sessionStorage.getItem('backRequest');

        if (back) {
          if (back?.includes('position_list')) {
            sessionStorage.setItem('menuVoice', JSON.stringify('Position List'))
          }

          this.createButton()

          sessionStorage.removeItem('backRequest');

        } else {

          sessionStorage.setItem('plan-purchased', 'true')
          this.createButton()
        }

      } else {
        if (this.contRequest <= 10) {
          this.contRequest++
          this.refreshSubscription(plan_id, date_for_check, title);
        } else {

          var back = sessionStorage.getItem('backRequest');

          if (back) {
            if (back?.includes('profile_available')) {
              sessionStorage.setItem('menuVoice', JSON.stringify('Profile Available'))
            } else if (back?.includes('need-matches')) {
              sessionStorage.setItem('menuVoice', JSON.stringify('Need'))
            }
            this.createButton()
            sessionStorage.removeItem('backRequest');

          } else {

            sessionStorage.setItem('plan-purchased', 'true')
            this.createButton()

          }
        }
      }

    }).catch(error => {
      if (error.status == 401) {
        this.refreshToken(1);

      } else {
      }

    });

  }

  createButton() {
    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);

    if (this.currentUser.role == 'Client' || this.currentUser.role == 'Client Employees') {
      if (this.currentUser.language == 'it') {

        // window.open("https://timeflow.it/thank-you-page-subscription-client/", "_self");

        var id = 'acquisto-piano-clienti-ita';
        var button = document.createElement('button');
        button.type = 'button';
        button.id = id;
        button.style.visibility = 'hidden';
        document.body.appendChild(button);

        var btn = document.getElementById(id);
        btn?.click();

        btn?.remove();

        window.open("https://timeflow.it/thank-you-page-subscription-client/", "_blank");

      } else {
        // window.open("https://timeflow.cloud/thank-you-page-subscription-client/", "_self");

        var id = 'acquisto-piano-clienti-non-ita';
        var button = document.createElement('button');
        button.type = 'button';
        button.id = id
        button.style.visibility = 'hidden';
        document.body.appendChild(button);

        var btn = document.getElementById(id);
        btn?.click();

        btn?.remove();

        window.open("https://timeflow.cloud/thank-you-page-subscription-client/", "_blank");

      }
    } else {
      if (this.currentUser.language == 'it') {
        // window.open("https://timeflow.it/thank-you-page-subscription-supplier/", "_self");

        var id = 'acquisto-piano-fornitori-ita'
        var button = document.createElement('button');
        button.type = 'button';
        button.id = id
        button.style.visibility = 'hidden';
        document.body.appendChild(button);

        var btn = document.getElementById(id);
        btn?.click();

        btn?.remove();

        window.open("https://timeflow.it/thank-you-page-subscription-supplier/", "_blank");

      } else {
        // window.open("https://timeflow.cloud/thank-you-page-subscription-supplier/", "_self");

        var id = 'acquisto-piano-fornitori-non-ita'

        var button = document.createElement('button');
        button.type = 'button';
        button.id = id
        button.style.visibility = 'hidden';
        document.body.appendChild(button);

        var btn = document.getElementById(id);
        btn?.click();

        btn?.remove();

        window.open("https://timeflow.cloud/thank-you-page-subscription-supplier/", "_blank");

      }

    }

  }

  getFormUrlEncoded(toConvert: any) {
    const formBody = [];
    for (const property in toConvert) {
      const encodedKey = encodeURIComponent(property);
      if (toConvert[property]) {
        const encodedValue = encodeURIComponent(toConvert[property]);
        formBody.push(encodedKey + '=' + encodedValue);
      }
    }
    return formBody.join('&');
  }

  getUnreadMessages() {
    this._service.getChatNewMessages().then(res => {
      this.newMessagesLed = res.data;
    })
  }
  setClosedBanner() {
    //console.log('m');

    this.seeBannerFeedback = false
    sessionStorage.setItem('bannerFeedback', 'setFalse')
  }

  openDialog(action: string, obj: any) {
    let dialogRef;
    obj.action = action;

    if (action == "feedbackRequest") {
      dialogRef = this.dialog.open(ExternalFeedbackModalComponent, {
        data: obj
      });

    } else {
      dialogRef = this.dialog.open(ExternalFeedbackModalComponent, {
        data: obj
      });
    }

    dialogRef.afterClosed().subscribe(result => {
      //console.log('inviato' + result);

    });

  }

  getPlanStatus() {

    sessionStorage.setItem('invocateGetPlanStatus', JSON.stringify(1));

    this.namePlanCurrent = null;

    if (this.currentUser.purchase_plan == 1 && window.location.pathname != '/registration_stepper') {

      this.openCheckout();
    }

    this._service.getPlanStatus().then(res => {

      sessionStorage.setItem('invocateGetPlanStatus', JSON.stringify(0));
      const format1 = "YYYY-MM-DD HH:mm:ss"
      var date2 = new Date();
      var date_for_check = moment(date2).format(format1);
      sessionStorage.setItem('dateInvocateGetPlanStatu', JSON.stringify(date_for_check));

      this.idPlanCurrent = res.data.idPlanCurrent;
      this.is_old_plan = res.data.isOldPlan;
      this.countPosition = res.data.countPosition;
      this.max_position = res.data.max_position;
      this.transactionFee = res.data.transactionFee;
      this.namePlanCurrent = res.data.namePlanCurrent;
      this.freePlan = res.data.freePlan;
      this.getPlanStatusRes = res.data;
      this.price = res.data.price;
      this.limit = res.data.limit;

      this.checkNumberDay = 0;
      this.planStatus = 0;
      if (res.data.length != 0) {
        if (res.data.numberDay > 0) {
          this.checkNumberDay++
        }
      } else {
        this.planStatus = 0;
      }

      if (this.planStatus == 0) {
        if (this.checkNumberDay != 0) {
          this.planStatus = 1;
        }
      }

      this.freePlan = res.data.freePlan;

      this.limitations = res.data.limitations;
      sessionStorage.removeItem('userLimitation');
      sessionStorage.setItem('userLimitation', JSON.stringify(this.limitations))
      this.limitationsArray = JSON.parse(sessionStorage.getItem('userLimitation')!)
      //console.log(this.limitationsArray);
      this.limitationsArray.forEach((element: any) => {
        switch (element.state_function) {
          case "manage_users_supplier":
            this.manageUserBlock = element
            break;
          case "SPC":
            this.discoverCompatibleProfileBlock = element
            break;
          case "tEm":
            this.timeMaterialBlock = element
            break;
          case "PFP":
            this.fixedPriceBlock = element
            break;
          case "VP":
            this.showEstimateBlock = element
            break;
          case "accademy_profiles":
            this.hireBlock = element
            break;
          case "chat":
            this.chatBlock = element
            break;
          case "request_available":
            this.requestAvailableBlock = element
            break;
          case "download_cv":
            this.downloadCVBlock = element
            break;
          case "information_blocked":
            this.informationBlock = element
            break;
          case "emails_contact":
            this.emailsContactBlock = element
            break;
          case "download_company":
            this.downloadCompanyReportBlock = element
            break;
          case "search_partner":
            this.searchPartnerBlock = element
            this.existInLimitationSearch = true;
            break;
          default:
            break;
        }
      });

      if (this.existInLimitationSearch == false) {
        this.searchPartnerBlock = { blocked: true, action: 1 };
        this.downloadCompanyReportBlock = { blocked: true, action: 1 };
      }

      this._service.getDocumentationStatus().then(res => {
        this.currentDocStatus = res.data;
        if (this.currentDocStatus && this.currentDocStatus.id_doc_status == 3 && this.currentUser.role != "Supplier Employees" && this.currentUser.role != "Profile Accademy") {
          this.router.navigate(['/authentication/login']);
        }
      }).catch(error => {
        if (error.status == 401) {
          this.refreshToken(1);

        } else {

        }

      });

    }).catch(error => {
      if (error.status == 401) {
        this.refreshToken(1);

      } else {

      }

    });
  }

  listFunction() {

    this._service.getFunctionCompany().then(res => {
      sessionStorage.removeItem('functions');
      sessionStorage.setItem('functions', JSON.stringify(res.data));

    }).catch(error => {
      if (error.status == 401) {
        this.refreshToken(1);

      } else {

      }

    });
  }

  setToggle(val: boolean) {
    this.status = val;
  }

  myCallbackFunction = (element: any): void => {

    var title = element;
    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);

    if (this.currentUser.language == 'it') {

      for (let [key, value] of Object.entries(this.translate.translations.it)) {
        if (title == key) {
          this.titlePage = value;
          //console.log(this.titlePage);

          this.titleService.setTitle(this.titlePage);
          this.titlePageKey = key;
        }

        if (title == 'My Profile' || title == 'aSecond Level' || title == 'Dashboards') {
          this.titlePage = '';
          this.titlePageKey = key;
        }
      }

    } else if (this.currentUser.language == 'en') {

      for (let [key, value] of Object.entries(this.translate.translations.en)) {
        if (title == key) {
          this.titlePage = value;
          this.titleService.setTitle(this.titlePage);
          this.titlePageKey = key
        }

        if (title == 'My Profile' || title == 'aSecond Level' || title == 'Dashboards') {
          this.titlePage = '';
          this.titlePageKey = key;
        }
      }

    }
  }

  clickEvent() {
    this.status = !this.status;
  }

  getCodificArray(type: any) {
    switch (type) {
      case 'E':
        return this.codificArrayE
        break;
      case 'R':
        return this.codificArrayR
        break;
      case 'S':
        return this.codificArrayS
        break;
      case 'TD':
        return this.codificArrayTd
        break;
      case 'PD':
        return this.codificArrayPd
        break;
      case 'NC':
        return this.codificArrayNc
        break;

      default:
        break;
    }
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);

  }

  // ngDoCheck(): void {

  //   if (sessionStorage.getItem('verifyBtn') == 'true') {
  //     this.getNumberCredits();
  //     sessionStorage.removeItem('verifyBtn');
  //   }
  // }

  setTitle() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter(route => route.outlet === 'primary'))
      .pipe(mergeMap(route => route.data))
      .subscribe(event => {
        setTimeout(() => {
          this.titleService.setTitle(event['title']);
          var title = event['title'];
          //console.log(event)

          this.assumedUser = JSON.parse(sessionStorage.getItem("assumedUser")!);

          // sessionStorage.removeItem('backRequest');
          if (title == 'Dashboard' || title == 'Dashboards') {
            this.titleService.setTitle(title);
          } else {
            if (this.currentUser.language == 'it') {

              for (let [key, value] of Object.entries(this.translate.translations.it)) {
                if (title == key) {
                  this.titlePage = value;
                  //console.log(this.titlePage);

                  this.titleService.setTitle(this.titlePage);
                  this.titlePageKey = key;

                  var objSegment = {};

                  objSegment = {
                    action: ((this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? '[STAFF] Nuova pagina : ' + this.titlePage : this.assumedUser == 1 ? '[STAFF] Nuova pagina : ' + this.titlePage : 'Nuova pagina : ' + this.titlePage),
                    sidebar_clic: this.titlePage,
                  };

                  if (this.activeSegmentDev == true) {

                    this.trackSegment(objSegment);
                  }

                  this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);
                  if (this.versionProduct == 2) {
                    /* IA - SEGMENT */
                    if (window.location.host == 'marketplace.timeflow.it') {
                      this.trackSegment(objSegment);
                    }
                  } else {

                    if (window.location.host == 'localhost:4200') {

                    } else {

                      if (window.location.host == "procurement.timeflow.it") {
                        this.trackSegment(objSegment);

                      } else if (window.location.host == "whitelabel.daytrend.it") {

                      } else if (window.location.host == "procurement.daytrend.it") {

                      } else if (window.location.host == "dev.mkt.daytrend.it") {

                      } else {
                        if (!(window.location.host.includes('daytrend'))) {

                          this.trackSegment(objSegment);

                        }
                      }
                    }
                  }

                }
                if (title == 'My Profile' || title == 'aSecond Level' || title == 'Dashboards') {
                  this.titlePage = '';
                  this.titlePageKey = key;
                }
              }

            } else if (this.currentUser.language == 'en') {

              for (let [key, value] of Object.entries(this.translate.translations.en)) {
                if (title == key) {
                  this.titlePage = value;
                  this.titleService.setTitle(this.titlePage);
                  this.titlePageKey = key;

                  var objSegment = {};

                  objSegment = {
                    action: ((this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? '[STAFF] Nuova pagina : ' + this.titlePage : this.assumedUser == 1 ? '[STAFF] Nuova pagina : ' + this.titlePage : 'Nuova pagina : ' + this.titlePage),
                    sidebar_clic: this.titlePage,
                  };

                  if (this.activeSegmentDev == true) {

                    this.trackSegment(objSegment);
                  }

                  this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);

                  if (this.versionProduct == 2) {

                    /* IA - SEGMENT */
                    if (window.location.host == 'marketplace.timeflow.it') {
                      this.trackSegment(objSegment);

                    }
                  } else {

                    if (window.location.host == 'localhost:4200') {

                    } else {

                      if (window.location.host == "procurement.timeflow.it") {
                        this.trackSegment(objSegment);

                      } else if (window.location.host == "whitelabel.daytrend.it") {

                      } else if (window.location.host == "procurement.daytrend.it") {

                      } else if (window.location.host == "dev.mkt.daytrend.it") {

                      } else {
                        if (!(window.location.host.includes('daytrend'))) {

                          this.trackSegment(objSegment);

                        }
                      }
                    }
                  }

                }
                if (title == 'My Profile' || title == 'aSecond Level' || title == 'Dashboards') {
                  this.titlePage = '';
                  this.titlePageKey = key;
                }
              }

            }
          }

          var invocateGetNumberCredits = JSON.parse(sessionStorage.getItem('invocateGetNumberCredits')!);
          var invocateGetPlanStatus = JSON.parse(sessionStorage.getItem('invocateGetPlanStatus')!);

          if ((this.currentUser.role == "Client" || this.currentUser.role == 'Client Employees') && (invocateGetNumberCredits && invocateGetNumberCredits == 0 || !invocateGetNumberCredits)) {
            this.getNumberCredits()
          } else if ((this.currentUser.role == "Supplier" || this.currentUser.role == 'Supplier Employees') && (invocateGetPlanStatus && invocateGetPlanStatus == 0 || !invocateGetPlanStatus)) {
            this.getPlanStatus()
          }

          let filtersCloud = JSON.parse(sessionStorage.getItem("filtersBackup")!);
          if (filtersCloud) {
            sessionStorage.removeItem('filtersBackup')
          }

        }, 800);

      });

  }

  refreshToken(viewModal: any) {
    if (this.counterCallRefreshToken == 0) {
      this.counterCallRefreshToken++;
      this._service.getRefreshToken(this.token.access_token).then(res => {
        sessionStorage.removeItem('loginUserToken');
        sessionStorage.setItem('loginUserToken', JSON.stringify(res.data.token));
        sessionStorage.removeItem('loginUser');
        sessionStorage.setItem('loginUser', JSON.stringify(res.data.user));
        this.counterCallRefreshToken = 0;
        window.location.reload();
      }).catch(error => {
        if (error.status == 401) {
          if (viewModal == 1) {
            if (this.countModaleExpired == 0) {
              this.countModaleExpired++;
              var obj;
              let dialogRef = this.dialog.open(SessionExpiredModalComponent, {
                data: obj
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result.event == 'ExtendSession') {
                  this.countModaleExpired = 0;
                  this.counterCallRefreshToken = 0;
                  window.location.reload();
                }
              })
            }
          } else {
            this.router.navigate(['/']);
          }

        } else {

        }

      });
    }

  }

  trackSegment(obj: any) {

    var date = new Date();
    var dateStr =
      ("00" + date.getDate()).slice(-2) + "/" +
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2) + ":" +
      ("00" + date.getSeconds()).slice(-2);

    this.currentUser = JSON.parse(sessionStorage.getItem("loginUser")!);
    this.assumedUser = JSON.parse(sessionStorage.getItem("assumedUser")!);

    var heightPage = window.screen.height;
    var widthPage = window.screen.width;

    mixpanel.init('71b2c8ca9dcb37477315518497a82c4a', { debug: false });

    this.versionProduct = JSON.parse(sessionStorage.getItem("versionProduct")!);

    if (this.versionProduct == 2) {

      if (obj.action.includes('Acquisto Addon Assumi')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage
        });

      } else if (obj.action.includes('Skip modale completa profilo')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          source: obj.source,
          project_id: obj.project_id,
          position_id: obj.position_id,
          profile_id: obj.id,
          supplier_id: obj.company_id,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage
        });

      } else if (obj.action.includes('Visualizzato dettaglio profilo')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          source: obj.source,
          project_id: obj.project_id,
          position_id: obj.position_id,
          profile_id: obj.id,
          supplier_id: obj.company_id,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage
        });

      } else if (obj.action.includes('Download CV profilo')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          source: obj.source,
          project_id: obj.project_id,
          position_id: obj.position_id,
          profile_id: obj.profile_id,
          profile_name: obj.profile_name,
          supplier_id: obj.supplier_id,
          supplier_name: obj.supplier_name,
          link_cv: obj.link_cv,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Richiesta disponibilità profilo')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          source: obj.source,
          project_id: obj.project_id,
          position_id: obj.position_id,
          profile_id: obj.profile_id,
          supplier_id: obj.supplier_id,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Rifiuto acquisto addon Assumi')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Chiusura modale per rifiuto acquisto funzionalità bloccate')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          source: obj.source,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Chat collaborazioni')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          position_id: obj.position_id,
          position_name: obj.position_name,
          referent_id: obj.referent_id,
          referent_name: obj.referent_name,
          profile_id: obj.profile_id,
          request_id: obj.request_id,
          nomination_id: obj.nomination_id,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Chat Dettaglio')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          position_id: obj.position_id,
          position_name: obj.position_name,
          referent_id: obj.referent_id,
          referent_name: obj.referent_name,
          profile_id: obj.profile_id,
          request_id: obj.request_id,
          nomination_id: obj.nomination_id,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Chat Invito')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          position_id: obj.position_id,
          position_name: obj.position_name,
          referent_id: obj.referent_id,
          referent_name: obj.referent_name,
          profile_id: obj.profile_id,
          request_id: obj.request_id,
          nomination_id: obj.nomination_id,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Collaborazioni: Cambio tab')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          name_tab: obj.name_tab,
          note: obj.note,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage
        });

      } else if (obj.action.includes('Collaborazioni azioni')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          position_id: obj.position_id,
          position_name: obj.position_name,
          profile_id: obj.profile_id,
          request_id: obj.request_id,
          nomination_id: obj.nomination_id,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          note: obj.note,
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Rifiuto acquisto piano fornitori a pagamento')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          source: obj.source,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Acquisto piano fornitori a pagamento')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          source: obj.source,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Profilo cliente')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          position_id: obj.position_id,
          source: obj.source,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          note: obj.note,
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Dettaglio azienda')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          position_id: obj.position_id,
          source: obj.source,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          note: obj.note,
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Dettaglio progetto')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          source: obj.source,
          tipo: obj.tipo,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage,
          note: obj.note

        });

      } else if (obj.action.includes('Dettaglio posizione')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          position_id: obj.position_id,
          source: obj.source,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          note: obj.note,
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Profilo aggiunto ai preferiti')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          source: obj.source,
          action: obj.actionSave,
          project_id: obj.project_id,
          position_id: obj.position_id,
          profile_id: obj.profile_id,
          supplier_id: obj.supplier_id,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Generazione link condivisione profili')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          company_name: this.currentUser.company_name,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          date: dateStr,
          link: obj.link,
          condivisione_lista: obj.condivisione_lista,
          profile_id: obj.profile_id,
          profile_name: obj.profile_name,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage
        });

      } else if (obj.action.includes('Aggiunto nuovo profilo')) {
        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          profile_id: obj.profile_id,
          profile_name: obj.profile_name,
          source: obj.source,
          project_completed: obj.project_completed,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          note: obj.note,
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Nuova pagina')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: !this.currentUser.company_name ? 'NULL' : this.currentUser.company_name,
          date: dateStr,
          sidebar_clic: obj.sidebar_clic,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage
        });

      } else if (obj.action.includes('Acquisti in app: Nuovo abbonamento')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          id_current_plan: obj.id_current_plan,
          plan_title: obj.plan_title,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          note: obj.note,
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Acquisti in app: Annulla acquisto piano')) {
        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          id_current_plan: obj.id_current_plan,
          plan_title: obj.plan_title,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          note: obj.note,
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Visualizzato preventivi')) {
        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          project_name: obj.project_name,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          note: obj.note,
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage
        });

      } else if (obj.action.includes('Visualizzato profili compatibili')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          project_name: obj.project_name,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          note: obj.note,
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage
        });

      } else if (obj.action.includes('Pubblicato nuovo progetto')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          project_name: obj.project_name,
          source: obj.source,
          project_sector: obj.project_sector,
          project_location: obj.project_location,
          project_completed: obj.project_completed,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          note: obj.note,
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage,
          tipo: obj.tipo
        });

      } else if (obj.action.includes('Visualizzato dettaglio Preventivo')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          project_name: obj.project_name,
          supplier_id: obj.supplier_id,
          supplier_name: obj.supplier_name,
          preventivo_id: obj.preventivo_id,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Download Preventivo')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          source: obj.source,
          project_id: obj.project_id,
          project_name: obj.project_name,
          supplier_id: obj.supplier_id,
          supplier_name: obj.supplier_name,
          preventivo_id: obj.preventivo_id,
          link_preventivo: obj.link_preventivo,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Scarta Preventivo')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          project_name: obj.project_name,
          supplier_id: obj.supplier_id,
          supplier_name: obj.supplier_name,
          preventivo_id: obj.preventivo_id,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Preventivo Preferito')) {
        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          project_name: obj.project_name,
          supplier_id: obj.supplier_id,
          supplier_name: obj.supplier_name,
          preventivo_id: obj.preventivo_id,
          action: obj.actionPref,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Chat Preventivo')) {
        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          source: obj.source,
          project_id: obj.project_id,
          project_name: obj.project_name,
          supplier_id: obj.supplier_id,
          supplier_name: obj.supplier_name,
          referent_id: obj.referent_id,
          referent_name: obj.referent_name,
          preventivo_id: obj.preventivo_id,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Profili compatibili azioni')) {
        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          source: obj.source,
          action: obj.actionProf,
          project_id: obj.project_id,
          position_id: obj.position_id,
          profile_id: obj.profile_id,
          supplier_id: obj.supplier_id,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          note: obj.note,
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Match: Cambio tab')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          name_tab: obj.name_tab,
          project_id: obj.project_id,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          note: obj.note,
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Visualizzato dettaglio Proposta')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          project_name: obj.project_name,
          supplier_id: obj.supplier_id,
          supplier_name: obj.supplier_name,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage

        });

      } else if (obj.action.includes('Download Analisi Funzionale')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          project_name: obj.project_name,
          source: obj.source,
          link_analisi: obj.link_analisi,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage
        });

      } else if (obj.action.includes('Preventivo Inviato')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          project_name: obj.project_name,
          project_completed: obj.project_completed,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage
        });

      } else if (obj.action.includes('Candidato profilo')) {

        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          project_id: obj.project_id,
          position_id: obj.position_id,
          profile_id: obj.profile_id,
          profile_name: obj.profile_name,
          Process_completed: obj.Process_completed,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          note: obj.note,
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage
        });
      } else if (obj.action.includes('Ricerca fornitore') || obj.action.includes('Download Report Compagnie')) {
        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage,
          company_name_search: obj.company_name_search,
          vat_number_search: obj.vat_number_search,
          location: obj.location,
          keywords: obj.keywords
        });
      } else if (obj.action.includes('Blocchi Dashboard')) {
        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage,
          company_id: obj.company_id,
          user_id: obj.user_id,
          link: obj.link,
          source: obj.source
        });
      } else if (obj.action.includes('Vai A Tutti Dashboard')) {
        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage,
          destination: obj.destination
        });
      } else if (obj.action.includes('Crea Progetto Dashboard')) {
        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage,
          project_type: obj.project_type
        });
      } else if (obj.action.includes('Chiudi Modale Dashboard')) {
        mixpanel.track(obj.action, {
          client_id: this.currentUser.id,
          user: this.currentUser.firstname + ' ' + this.currentUser.lastname,
          company_name: this.currentUser.company_name,
          date: dateStr,
          role: this.currentUser.role == 'Client' ? 'Cliente' : this.currentUser.role == 'Supplier' ? 'Fornitore' : this.currentUser.role == 'Administrator' ? 'Staff TimeFlow' :
            this.currentUser.role == 'Supplier Employees' ? 'Profilo fornitore' : this.currentUser.role == 'Client Employees' ? 'Aiuto amministratore cliente' : this.currentUser.role == 'Supplier Management' ?
              'Aiuto amministratore fornitore' : this.currentUser.role == 'Accademy' ? 'Academy' : this.currentUser.role == 'Profile Accademy' ? 'Profilo academy' : this.currentUser.role == 'Management Accademy' ?
                'Aiuto amministratore academy' : this.currentUser.role == 'Admin Management' ? 'Aiuto amministratore staff TimeFlow' : 'Freelance',
          plan: this.currentUser.planName,
          assume: this.currentUser.assumeSubscription == false ? 'Non visualizzo assumi' : 'Visualizzo assumi',
          staff: (this.currentUser.role == 'Administrator' || this.currentUser.role == 'Admin Management') ? true : this.assumedUser == 1 ? true : false,
          width_page: widthPage,
          heigth_page: heightPage,
          request: obj.request
        });
      }

    }

  }

  // VECCHIE FUNZIONI TUTORIAL PARACADUTE

  closeTutorialPopUpOld(idPage: any, statePage: any, namePage: any) {
    let tutorialPage = JSON.parse(sessionStorage.getItem("tutorialPage")!);
    let stateTutorial = 0

    if (tutorialPage.length != 0) {
      for (let i = 0; i < tutorialPage.length; i++) {
        if (tutorialPage[i]['page_id'] == idPage) {
          var id = tutorialPage[i]['id'];
          tutorialPage.splice(i, 1);
        }
      }

      stateTutorial = 0;

      var objectTutorial = {
        'page_id': idPage,
        'state': statePage,
        'name_page': namePage,
        'id': id
      }
      if (tutorialPage.length == 0) {
        tutorialPage = [];
      }
      tutorialPage.push(objectTutorial);
      sessionStorage.removeItem('tutorialPage');
      sessionStorage.setItem('tutorialPage', JSON.stringify(tutorialPage));

      this._service.setTutorialPage(objectTutorial).then(res => {

      }).catch(error => {
        if (error.status == 401) {
          this.refreshToken(1);

        }

      });
    }
  }

  setTutorialPageOld(idPage: any, statePage: any, namePage: any) {
    let tutorialPage = JSON.parse(sessionStorage.getItem("tutorialPage")!);
    let stateTutorial = 0;

    if (tutorialPage.length == 0) {

      var array = [];
      /* IA - AGGIUNGIAMO IL VALORE STATE NELLA LISTA */
      var objectTutorial = {
        'page_id': idPage,
        'state': statePage,
        'name_page': namePage
      }

      array.push(objectTutorial)
      sessionStorage.setItem('tutorialPage', JSON.stringify(array));

      stateTutorial = 1;

      this._service.setTutorialPage(objectTutorial).then(res => {

      }).catch(error => {
        if (error.status == 401) {
          this.refreshToken(1);

        }
      });

    } else {

      /* IA - CERCHIAMO NELLA LISTA SE è PRESENTE IL VALORE */
      var countPage = 0;
      for (let i = 0; i < tutorialPage.length; i++) {
        if (tutorialPage[i]['page_id'] == idPage) {
          stateTutorial = tutorialPage[i]['state'];
          countPage++;
        }
      }

      if (countPage == 0) {

        var array = [];

        for (let y = 0; y < tutorialPage.length; y++) {
          array.push(tutorialPage[y]);
        }

        /* IA - AGGIUNGIAMO IL VALORE STATE NELLA LISTA */
        var objectTutorial = {
          'page_id': idPage,
          'state': statePage,
          'name_page': namePage
        }
        array.push(objectTutorial)

        sessionStorage.setItem('tutorialPage', JSON.stringify(array));

        stateTutorial = 1;

        this._service.setTutorialPage(objectTutorial).then(res => {

        }).catch(error => {
          if (error.status == 401) {
            this.refreshToken(1);

          }

        });
      }
    }
  }

  onChildLoaded(
    component:
      NeedComponent |
      ProfileAvailableComponent |
      MatchesComponent |
      CollaborationsInviteComponent |
      MyPlansComponent |
      MyProfileComponent |
      CompanyProfileComponent |
      EmployeesComponent |
      CollaborationsRequestComponent |
      CustomerProfileComponent |
      DigitizeCvComponent |
      ManageUsersComponent |
      ManageUsersSupplierComponent |
      PositionListComponent |
      AddProjectComponent |
      AddProfileComponent |
      AddEmployeeComponent |
      SavedProfilesComponent |
      PlansComponent |
      NotificationsPageComponent |
      CompaniesProposeComponent |
      CompleteDetailsComponent |
      EServicesListComponent |
      EServicesReviewComponent |
      ECompanyListComponent |
      ECompanyReviewComponent |
      EmailNotificationDetailComponent,
      component_codific:
      CompanyProfileSupplierComponent |
      CompanyProfileComponent |
      EFindServicesComponent |
      ERegistrationStepperComponent |
      SearchSupplierComponent
  ) {
    setTimeout(() => {
      component.sharedTitle = this.titlePage;
      component.manageUserBlock = this.manageUserBlock;
      component.discoverCompatibleProfileBlock = this.discoverCompatibleProfileBlock;
      component.timeMaterialBlock = this.timeMaterialBlock;
      component.fixedPriceBlock = this.fixedPriceBlock;
      component.showEstimateBlock = this.showEstimateBlock;
      component.hireBlock = this.hireBlock;
      component.chatBlock = this.chatBlock;
      component.state = this.state;
      component.globalState = this.globalState;
      component.planStatus = this.planStatus;
      component.idPlanCurrent = this.idPlanCurrent;
      component.is_old_plan = this.is_old_plan;
      component.countPosition = this.countPosition;
      component.max_position = this.max_position;
      component.transactionFee = this.transactionFee;
      component.namePlanCurrent = this.namePlanCurrent;
      component.freePlan = this.freePlan;
      component.getPlanStatusRes = this.getPlanStatusRes;
      component.currentDocStatus = this.currentDocStatus;
      component_codific.codificArrayE = this.codificArrayE;
      component_codific.codificArrayR = this.codificArrayR;
      component_codific.codificArrayS = this.codificArrayS;

      let logo;
      logo = this.appComponent.logoSpinner

      component.logo = logo
    }, 900);

  }
  // Mini sidebar
}
